import {
    fetchGet,
    fetchPut,
    fetchPost,
    fetchDelete,
    jsonToQueryParam,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

/*tiers section*/
const getTiers = ({ limit = 100, skip = 0, regionId }) => {
    return fetchGet(
        `${BASE_URL}tiers?${jsonToQueryParam({
            limit,
            skip,
            regionId,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TIER,
            AccessPermissionModules[AccessPermissionModuleNames.TIER].actions
                .ListTiers
        )
    );
};
const createTiers = ({ name, benefits, imageUrl, points, regionId }) => {
    return fetchPost(
        `${BASE_URL}tiers`,
        { regionId, name, imageUrl, benefits, points },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TIER,
            AccessPermissionModules[AccessPermissionModuleNames.TIER].actions
                .CreateTier
        )
    );
};

const editTiers = ({ _id, name, benefits, imageUrl, points }) => {
    return fetchPut(
        `${BASE_URL}tiers/${_id}`,
        { name, benefits, imageUrl, points },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TIER,
            AccessPermissionModules[AccessPermissionModuleNames.TIER].actions
                .UpdateTier
        )
    );
};

const deleteTiers = (id) => {
    return fetchDelete(
        `${BASE_URL}tiers/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TIER,
            AccessPermissionModules[AccessPermissionModuleNames.TIER].actions
                .DeleteTier
        )
    );
};

export { getTiers, editTiers, createTiers, deleteTiers };
