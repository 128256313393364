import {
    fetchGet,
    fetchPut,
    fetchPost,
    jsonToQueryParam,
    fetchPostMultipart,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/rewarddistributionjobs`;

/* Rewards - Logistics */
const getRewardDistributionJobs = (queryObj) => {
    //Query params : limit, skip, regionId, status, batchId, rewardId, createdOnFrom, createdOnTo
    return fetchGet(
        `${BASE_URL}?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .ListRewardDistributionJobs
        )
    );
};

/* Reward distribution jobs */

const createRewardDistributionJob = (payload) => {
    return fetchPost(
        BASE_URL,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .CreateRewardDistributionJob
        )
    );
};

const exportRedemptionLogs = (queryObj) => {
    // Query params : distributionJobId
    return fetchGet(`${BASE_URL}/getcsv?${jsonToQueryParam(queryObj)}`);
    /*TODO:ADD API PERMISSION*/
};

const uploadRewardDistributionJobsFile = (queryObj, fileFormData) => {
    return fetchPostMultipart(
        `${BASE_URL}/uploadfile?${jsonToQueryParam(queryObj)}`,
        fileFormData
    );
};

const importRewardDistributionJobs = (payload) => {
    return fetchPost(
        BASE_URL + "/importjobs",
        payload,
        true
        // TODO: Add permissions.
        // getPermissionPath(
        //     AccessPermissionModuleNames.TRANSACTION,
        //     AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION]
        //         .actions.CreateTransactionImportJob
        // )
    );
};

const updategetRewardDistributionLogs = (_id, payload) => {
    return fetchPut(
        `${BASE_URL}/${_id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .UpdateRewardDistributionJob
        )
    );
};

export {
    getRewardDistributionJobs,
    createRewardDistributionJob,
    updategetRewardDistributionLogs,
    exportRedemptionLogs,
    uploadRewardDistributionJobsFile,
    importRewardDistributionJobs,
};
