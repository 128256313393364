import {
  fetchGet,
  jsonToQueryParam,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { getPermissionPath } from "../Utils";
import { AccessPermissionModuleNames, AccessPermissionModules } from "../Data";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/subtransactiontypes`;

const getSubTransactionTypes = ({
  limit=100,
  skip=0,
  searchKey,
  transactionType,
  createdBy,
}) => {
  return fetchGet(
    `${BASE_URL}?${jsonToQueryParam({
      limit,
      skip,
      searchKey,
      transactionType,
      createdBy,
    })}`,
      true,
      getPermissionPath(
          AccessPermissionModuleNames.SUB_TRANSACTION_TYPE,
          AccessPermissionModules[AccessPermissionModuleNames.SUB_TRANSACTION_TYPE]
              .actions.ListSubTransactionTypes
      )
  );
};

const getAllSubTransactionTypes=(args)=>{
    return new Promise(async (resolve, reject) => {
        const limit = 500;
        let totalCount = 0,
          subTransactionTypes = [];
        try {
          do {
            const subTransactionTypesResponse = await getSubTransactionTypes({
              limit,
              skip: subTransactionTypes.length,
              ...args,
            });
            totalCount = subTransactionTypesResponse.total;
            subTransactionTypes = [
              ...subTransactionTypes,
              ...subTransactionTypesResponse.items,
            ];
          } while (subTransactionTypes.length < totalCount);
          resolve(subTransactionTypes);
        } catch (e) {
          reject(e);
        }
      });
}
export { getSubTransactionTypes ,getAllSubTransactionTypes};
