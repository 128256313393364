const convertArrayToObject = (array, key, setValue) => {
    return array.reduce((obj, item) => {
        if (setValue) {
            obj[item[key]] = setValue ;
        } else {
            obj[item[key]] = item[key] ;
        };
        return obj;
    }, {});
};

const optionsToArray = options => {
    delete options._id;
    const newArr = Object.keys(options);
    return newArr.map(option => {
        return {
            "key": option,
            "value": (options[option])
        };
    });
};

export { convertArrayToObject, optionsToArray };