import {
    faCalendar, 
    faCreditCard, 
    faHome, 
    faUsers, 
    faUser,
    faUserPlus, 
    faGift
} from "FaICIconMap";
  
const PointRuleTypes = {
    TRANSACTIONAL: "TRANSACTIONAL",
    NON_TRANSACTIONAL: "NON_TRANSACTIONAL"
};
  
const PointRuleSubTypes = {
    GENERAL: {
        name: "General Spending Rule",
        value: "GENERAL",
        icon: faCreditCard
    },
    LOCATION: {
        name: "Location Based Spending Rule",
        value: "LOCATION",
        icon: faHome
    },
    SEASONAL: {
        name: "Seasonal Based Spending Rule",
        value: "SEASONAL",
        icon: faCalendar
    },
    AFFINITY: {
        name: "Affinity Group Spending Rule",
        value: "AFFINITY",
        icon: faUsers
    },
    ENROLL: {
        name: "Member Enroll",
        value: "ENROLL",
        icon: faUser
    },
    SIGNUP: {
        name: "Member Sign Up",
        value: "SIGNUP",
        icon: faUserPlus
    },
    BIRTHDAY: {
        name: "Member Birth Day",
        value: "BIRTHDAY",
        icon: faGift
    }
};

const PointRuleStatus = {
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
    ARCHIVED: "ARCHIVED"
};

const PointRuleStates = {
    ACTIVE: "ACTIVE",
    EXPIRED: "EXPIRED",
    SCHEDULED: "SCHEDULED"
};
  
const AffinityGroupOptions = {
    ENTIER: "ENTIER",
    TIERS: "TIERS",
};

const Occasions = {
    NONE: "NONE",
    FOREVER: "FOREVER",
    PERIOD: "PERIOD"
};

const LocationSeasonalSubTypes = [PointRuleSubTypes.LOCATION.value, PointRuleSubTypes.SEASONAL.value];

const OccasionWithDates = [Occasions.NONE, Occasions.PERIOD];

const OccasionWithDays = [Occasions.FOREVER, Occasions.PERIOD];

const PointRuleStatusColorCode = {
    [PointRuleStatus.ENABLED]: "success",
    [PointRuleStatus.DISABLED]: "warning",
    [PointRuleStatus.ARCHIVED]: "danger",
};
    
export { 
    PointRuleTypes, 
    PointRuleSubTypes, 
    PointRuleStatus,
    PointRuleStates, 
    AffinityGroupOptions, 
    Occasions,
    LocationSeasonalSubTypes,
    OccasionWithDates,
    OccasionWithDays,
    PointRuleStatusColorCode 
};