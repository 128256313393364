import React  from "react";
import { BasicConfig, Utils as QbUtils, Widgets, } from "@shoutout-labs/react-awesome-query-builder-shoutout";
const { VanillaTextWidget,VanillaSelectWidget,VanillaDateWidget,VanillaNumberWidget} = Widgets;

delete BasicConfig?.operators["proximity"];
const InitialConfig = {
    ...BasicConfig,
    operators: {
        ...BasicConfig.operators,
        ...(BasicConfig.operators.proximity&&{}),
        equal: {
            ...BasicConfig.operators.equal,
            label: "Equals",
        },
        not_equal: {
            ...BasicConfig.operators.not_equal,
            label: "Not equals",
        },
        like: {
            ...BasicConfig.operators.like,
            label: "Contains",
        },
        not_like: {
            ...BasicConfig.operators.not_like,
            label: "Not contains",
        },
        is_empty: {
            ...BasicConfig.operators.is_empty,
            label: "Is unknown",
        },
        is_not_empty: {
            ...BasicConfig.operators.is_not_empty,
            label: "Is known",
        },
        less: {
            ...BasicConfig.operators.less,
            label: "Less than",
        },
        less_or_equal: {
            ...BasicConfig.operators.less_or_equal,
            label: "Less than or equal",
        },
        greater: {
            ...BasicConfig.operators.greater,
            label: "Greater than",
        },
        greater_or_equal: {
            ...BasicConfig.operators.greater_or_equal,
            label: "Greater than or equal",
        },
        array_empty: {
            label: "Empty",
            reversedOp: "array_not_empty",
            labelForFormat: "NULL",
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
                `${field} ${opDef.labelForFormat}`,
            mongoFormatOp: (field, op, value) => ({
                [field]: { $exist: true, $size: 0 },
            }),
        },
        greater_than_days: {
            label: "Greater Than Days",
            labelForFormat: ">",
            jsonLogic: ">",
            mongoFormatOp: (field, op, value) => ({ [field]: { $gt: value } }),
        },
        less_than_days: {
            label: "Less Than Days",
            labelForFormat: "<",
            jsonLogic: "<",
            mongoFormatOp: (field, op, value) => ({ [field]: { $lt: value } }),
        },
        greater_than_months: {
            label: "Greater Than Months",
            labelForFormat: ">",
            jsonLogic: ">",
            mongoFormatOp: (field, op, value) => ({
                $expr: {
                        "$gt": [
                            {
                                "$month": `$${field}`
                            },
                            value
                        ]
                    }
            }),
        },
        less_than_months: {
            label: "Less Than Months",
            labelForFormat: "<",
            jsonLogic: "<",
            mongoFormatOp: (field, op, value) => ({
                $expr: {
                    "$lt": [
                        {
                            "$month": `$${field}`
                        },
                        value
                    ]
                }
            }),
        },
        greater_than_years: {
            label: "Greater Than Years",
            labelForFormat: ">",
            jsonLogic: ">",
            mongoFormatOp: (field, op, value) => ({
                $expr: {
                    "$gt": [
                        {
                            "$year": `$${field}`
                        },
                        value
                    ]
                }
            }),
        },
        less_than_years: {
            label: "Less Than Years",
            labelForFormat: "<",
            jsonLogic: "<",
            mongoFormatOp: (field, op, value) => ({
                $expr: {
                    "$lt": [
                        {
                            "$year": `$${field}`
                        },
                        value
                    ]
                }
            }),
        },
        month_is: {
            label: "Month is",
            labelForFormat: "==",
            jsonLogic: "==",
            mongoFormatOp: (field, op, value) => ({
                $expr: {
                    "$eq": [
                        {
                            "$month": `$${field}`
                        },
                        value
                    ]
                }
            }),
        },
        day_is: {
            label: "Day is",
            labelForFormat: "==",
            jsonLogic: "==",
            mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
        },
        year_is: {
            label: "Year is",
            labelForFormat: "==",
            jsonLogic: "==",
            mongoFormatOp: (field, op, value) => ({
                $expr: {
                    "$eq": [
                        {
                            "$year": `$${field}`
                        },
                        value
                    ]
                }
            }),
        },
        array_not_empty: {
            label: "Not Empty",
            reversedOp: "array_empty",
            labelForFormat: "NOT NULL",
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
                `${field} ${opDef.labelForFormat}`,
            mongoFormatOp: (field, op, value) => ({
                [field]: { $exist: true, $not: { $size: 0 } },
            }),
        },
    },
    types: {
        ...BasicConfig.types,
        date: {
            ...BasicConfig.types.date,
            widgets: {
                ...BasicConfig.types.date.widgets,
                date: {
                    operators: [
                        ...BasicConfig.types.date.widgets.date.operators,
                        "year_is",
                        "day_is",
                        "month_is",
                        "less_than_years",
                        "greater_than_years",
                        "less_than_months",
                        "greater_than_months",
                        "less_than_days",
                        "greater_than_days"
                    ]
                }
            }
        },
    },
    widgets: {
        ...BasicConfig.widgets,
        text: {
            ...BasicConfig.widgets.text,
            factory: (props) => {
                return (
                    <VanillaTextWidget {...props} allowNew={true} clearButton />
                );
            },
        },
        select: {
            ...BasicConfig.widgets.select,
            factory: props => <VanillaSelectWidget {...props} />,

        },
        date: {
            type: 'date',
            valueSrc: 'value',
            dateFormat:'YYYY-MM-DD',
            valueFormat:'YYYY-MM-DD',
            factory: (props) => {
                switch(props.operator) {
                    case "month_is":
                    case "less_than_months":
                    case "greater_than_months":
                        return <VanillaNumberWidget {...props} min={1} max={12}/>
                    case "year_is":
                    case "less_than_years":
                    case "greater_than_years":
                        return <VanillaNumberWidget {...props}/>
                    default:
                        return <VanillaDateWidget {...props} />
                }

            },
        },
    },
    settings: {
        maxNesting: 1, //no groups
        maxNumberOfRules: 6,
        showErrorMessage: true,
        showNot: false,
    },
};

const buildMemberFilterConfig = (contactAttributes, tags) => {
    const attributes = Object.entries(contactAttributes).reduce(
        (result, [key, value]) => {
            switch (value.type) {
                case "string":
                    if (value.values && value.values.length > 0) {
                        result[key] = {
                            label: value.label,
                            type: "text",
                            useAsyncSearch: true,
                            useLoadMore: true,
                            forceAsyncSearch: false,
                            allowCustomValues: true,
                            asyncFetch: (search) => {
                                return {
                                    values: value.values.map((item) => {
                                        if (typeof item === "object") {
                                            return {
                                                title: item.label || item.value,
                                                value: item.value,
                                            };
                                        }
                                        return {
                                            title: item,
                                            value: item,
                                        };
                                    }),
                                    hasMore: false,
                                };
                            },
                        }; //TODO: Suggestions are there to show up which can't show without updating the widget
                    } else {
                        result[key] = {
                            label: value.label,
                            type: "text",
                            allowCustomValues: true,
                        };
                    }
                    break;

                case "array": {
                    result[key] = {
                        label: value.label,
                        type: "select",
                        operators: [
                            "select_any_in",
                            "select_not_any_in",
                            "array_empty",
                            "array_not_empty",
                        ],
                        fieldSettings: value.fieldSettings
                            ? value.fieldSettings
                            : {
                                  listValues: [
                                      {
                                          value: "0",
                                          title: `${value.label} not found`,
                                      },
                                  ],
                              },
                    };

                    break;
                }
                case "select": {
                    result[key] = {
                        label: value.label,
                        type: "select",
                        fieldSettings:value.fieldSettings.length!==0? {
                            listValues:value.fieldSettings,
                        }:{
                            listValues: [
                                {
                                    value: "0",
                                    title: `${value.label} not found`,
                                },
                            ],
                        },

                    };

                    break;
                }
                case "date": {
                    result[key] = {
                        label: value.label,
                        type: "date",
                        valueSources: ['value'],
                        operators: [
                            "equal",
                            "not_equal",
                            "less",
                            "less_or_equal",
                            "greater",
                            "greater_or_equal",
                            "between",
                            "not_between",
                            "year_is",
                            "day_is",
                            "month_is",
                            "less_than_years",
                            "greater_than_years",
                            "less_than_months",
                            "greater_than_months",
                            "less_than_days",
                            "greater_than_days",
                            "is_not_empty"
                        ],
                    };

                    break;
                }
                default: {
                    result[key] = { label: value.label, type: value.type };
                }
            }

            return result;
        },
        {}
    );
    if (attributes.tags && tags) {
        attributes.tags.fieldSettings = {
            listValues: tags.map(({ label, tag_key }) => ({
                title: label,
                value: label,
            })),
            showSearch: true,
        };
        attributes.tags.valueSources = ["value"];
    }

    return {
        ...InitialConfig,
        fields: attributes,
    };
};

const getMongoDBQuery = (filters, config) => {
    const filterData = QbUtils.checkTree(QbUtils.loadTree(filters), config);

    return QbUtils.mongodbFormat(filterData, config);
};
const generateUserAttribute = (projection) => {
    return projection.reduce((result, item) => {
        switch (item) {
            case "contact": {
                result.push("email");
                result.push("mobileNumber");
                break;
            }

            case "name": {
                result.push("firstName");
                result.push("lastName");
                result.push("loyaltyId");
                break;
            }

            default: {
                result.push(item);
            }
        }
        return result;
    }, []);
};
export { buildMemberFilterConfig, getMongoDBQuery, generateUserAttribute };
