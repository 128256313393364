import {sortCaret} from "../Components/analyticsView/utils/AnalyticsUtility"
const ChartColorCodes = {
    BLUE: "#4CADE9",
    DARK_BLUE: "#11355C",
    GREEN: "#61CE6C",
    ORANGE: "#E57F3A",
    PINK: "#EA369C",
    PURPLE: "#7C25DA",
    YELLOW: "#FADF4B"
};

const PieChartsColorCodes = {
    REG_BY_REG_METHODS : [ChartColorCodes.GREEN, ChartColorCodes.ORANGE, ChartColorCodes.YELLOW],
    REG_BY_ACC_TYPES : [ChartColorCodes.PURPLE, ChartColorCodes.PINK, ChartColorCodes.YELLOW],
    REG_BY_GENDER : [ChartColorCodes.BLUE, ChartColorCodes.YELLOW, ChartColorCodes.ORANGE,ChartColorCodes.GREEN],
    TIER : [ChartColorCodes.PURPLE, ChartColorCodes.BLUE, ChartColorCodes.GREEN],
    AGE : [ChartColorCodes.PURPLE, ChartColorCodes.YELLOW, ChartColorCodes.BLUE, ChartColorCodes.ORANGE, ChartColorCodes.GREEN, ChartColorCodes.PINK, ChartColorCodes.DARK_BLUE],
    POINT_EARNING_BY_ACC_TYPES : [ChartColorCodes.PURPLE, ChartColorCodes.PINK, ChartColorCodes.YELLOW],
    POINT_EARNING_BY_ACC_AGE : [ChartColorCodes.PURPLE, ChartColorCodes.YELLOW, ChartColorCodes.BLUE, ChartColorCodes.ORANGE, ChartColorCodes.GREEN, ChartColorCodes.PINK, ChartColorCodes.DARK_BLUE],
    POINT_EARNING_BY_ACC_GENDER : [ChartColorCodes.BLUE, ChartColorCodes.YELLOW, ChartColorCodes.ORANGE],
    POINT_REDEMPTION_BY_ACC_TYPES : [ChartColorCodes.GREEN, ChartColorCodes.YELLOW, ChartColorCodes.ORANGE],
    POINT_REDEMPTION_BY_ACC_AGE : [ChartColorCodes.PURPLE, ChartColorCodes.YELLOW, ChartColorCodes.BLUE, ChartColorCodes.ORANGE, ChartColorCodes.GREEN, ChartColorCodes.PINK, ChartColorCodes.DARK_BLUE],
    POINT_REDEMPTION_BY_ACC_GENDER : [ChartColorCodes.BLUE, ChartColorCodes.YELLOW, ChartColorCodes.ORANGE],
    POINT_ADJUSTMENT_BY_ACC_TYPES : [ChartColorCodes.GREEN, ChartColorCodes.YELLOW, ChartColorCodes.ORANGE],
    POINT_ADJUSTMENT_BY_ACC_AGE : [ChartColorCodes.PURPLE, ChartColorCodes.YELLOW, ChartColorCodes.BLUE, ChartColorCodes.ORANGE, ChartColorCodes.GREEN, ChartColorCodes.PINK, ChartColorCodes.DARK_BLUE],
    POINT_ADJUSTMENT_BY_ACC_GENDER : [ChartColorCodes.BLUE, ChartColorCodes.YELLOW, ChartColorCodes.ORANGE],
    CARD_REGISTRATION : [ChartColorCodes.GREEN, ChartColorCodes.ORANGE],
    MERCHANT_POINT_MOVEMENTS : [ChartColorCodes.GREEN, ChartColorCodes.PURPLE]
};

const BarChartColorCodes = {
    REWARD : ChartColorCodes.PURPLE,
    MERCHANT_POINT : ChartColorCodes.GREEN,
    MERCHANT_LOCATION_POINT : ChartColorCodes.PURPLE,
    MERCHANT_POINT_EARNING : ChartColorCodes.PINK,
    MERCHANT_LOCATION_EARNING : ChartColorCodes.PURPLE
};

const CardStatusFilter = {
    "All Statuses" : {
        key : "ALL"
    },
    "Assigned": {
        key : "ASSIGNED"
    },
    "Deactivated": {
        key : "DEACTIVATED"
    },
    "Suspended": {
        key : "SUSPENDED"
    }
};

const PointAnalyticsTabs = {
    POINT_EARNINGS : "POINT_EARNINGS",
    POINT_REDEMPTIONS : "POINT_REDEMPTIONS",
    POINT_ADJUSTMENTS : "POINT_ADJUSTMENTS"
};

const TransactionType ={
    POINT_EARNINGS : "collections",
    POINT_REDEMPTIONS : "redemptions",
    POINT_ADJUSTMENTS : "adjustments"
}
const SubTransactionTypeHeader ={
    POINT_EARNINGS : "Point Earning by Sub Transaction Types",
    POINT_REDEMPTIONS : "Point Redemption by Sub Transaction Types",
    POINT_ADJUSTMENTS : "Point Adjustment by Sub Transaction Types"
}

const MerchantPointHeader ={
    POINT_EARNINGS : "Point Earning",
    POINT_REDEMPTIONS : "Point Redemption",
    POINT_ADJUSTMENTS : "Point Adjustment"
}

const  PointAccountTypeHeader ={
    POINT_EARNINGS : "Point Earning by Account Type",
    POINT_REDEMPTIONS : "Point Redemption by Account Type",
    POINT_ADJUSTMENTS : " Point Adjustment by Account Type"
}
const  PointAgeHeader ={
    POINT_EARNINGS : "Point Earning by Age",
    POINT_REDEMPTIONS : "Point Redemption by Age",
    POINT_ADJUSTMENTS : "Point Adjustment by Age"
}
const  BarChartDataProperties ={
    POINT_EARNINGS : ["dateBucketKey","sum"],
    POINT_REDEMPTIONS : ["dateBucketKey","sum"],
    POINT_ADJUSTMENTS : ["dateBucketKey","addedPoints","subtractedPoints"],
    REDEMPTIONS_ANALYTICS:["dateBucketKey","count"],
    CARD_ACTIVATION : ["dateBucketKey","keyTagCards","digitalCards"],
    NEW_MEMBERS : ["dateBucketKey","returnMembers","newMembers"],
}

const MemberRegisterFilter = [
    {
        value: "Age",
        label: "Age"
    },
    {
        value: "Gender",
        label: "Gender"
    }
];

const GenderFilter = [
    {
        value: "MALE",
        label: "Male"
    },
    {
        value: "FEMALE",
        label: "Female"
    },
    {
        value: "OTHER",
        label: "Other"
    }
];

const AgeGroupFilter = [
    {
        value: "0-18",
        label: "0 - 18"
    },
    {
        value: "18-24",
        label: "18 - 24"
    },
    {
        value: "25-34",
        label: "25 - 34"
    },
    {
        value: "35-44",
        label: "25 - 44"
    },
    {
        value: "45-54",
        label: "45 - 54"
    },
    {
        value: "55-64",
        label: "55 - 64"
    },
    {
        value: `${encodeURIComponent("65+")}`,
        label: "Above 65"
    },
];

const MemberRegistrationGroupByTypes = {
    REGISTRATION_METHOD : "REGISTRATION_METHOD",
    AGE : "AGE",
    TYPE : "TYPE",
    BRANCH : "BRANCH",
    GENDER : "GENDER"
};

const MemberAffinityGroupByTypes = {
    TIER : "TIER"
};

const MerchantGroupByTypes = {
    MERCHANT: "MERCHANT",
    MERCHANT_LOCATION: "MERCHANT_LOCATION",
    MEMBER_TYPE: "MEMBER_TYPE",
    SUB_TRANSACTION_TYPE: "SUB_TRANSACTION_TYPE",
    AGE: "AGE",
    GENDER: "GENDER"
};
const AnalyticsTable = {
    REWARD_LIST: "REWARD_LIST",
    AFFINITY_GROUPS: "AFFINITY_GROUPS",
    TIERS: "TIERS",
};
const AnalyticsTableColumns = {
    REWARD_LIST: [
        {
            dataField: "name",
            text: "Reward Name",
        },
        {
            dataField: "redeemedCount",
            text: "Redeemed Count",
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "claimedCount",
            text: "Claimed Count",
            sort: true,
            sortCaret: sortCaret
        }
    ],
    AFFINITY_GROUPS: [
        {
            dataField: "name",
            text: "Affinity Groups",
        },
        {
            dataField: "earnedPoints",
            text: " Earned ",
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "redeemedPoints",
            text: "Redeemed  ",
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "expiredPoints",
            text: "Expired ",
            sort: true,
            sortCaret: sortCaret
        }
    ],
    TIERS:  [
        {
            dataField: "name",
            text: "Tiers Name",
        },
        {
            dataField: "earnedPoints",
            text: " Earned ",
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "redeemedPoints",
            text: "Redeemed ",
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "expiredPoints",
            text: "Expired ",
            sort: true,
            sortCaret: sortCaret
        }
    ],
};
const AnalyticsTopListURL = {
    REWARD_LIST: "rewards/redeemedrewards/count",
    AFFINITY_GROUPS: "tiers/affinitygroups/count",
    TIERS: "tiers/count",
};
const AnalyticsActiveExport = {
    POINT_EARNINGS : "POINT_EARNINGS_EXPORT",
    POINT_REDEMPTIONS : "POINT_REDEMPTIONS_EXPORT",
    POINT_ADJUSTMENTS : "POINT_ADJUSTMENTS_EXPORT",
    REWARD_REDEMPTION: "REWARD_REDEMPTION_EXPORT",
    CARD_ACTIVATION: "CARD_ACTIVATION_EXPORT",
    LOCATION_BASED_REGISTRATION: "LOCATION_BASED_REGISTRATION_EXPORT",
    REDEMPTION_BY_MERCHANT: "REDEMPTION_BY_MERCHANT_EXPORT",
    ADJUSTMENT_BY_MERCHANT: "ADJUSTMENT_BY_MERCHANT_EXPORT",
    EARNING_BY_MERCHANT: "EARNING_BY_MERCHANT_EXPORT",
    NEW_MEMBERS_AND_RETURN_MEMBERS: "NEW_MEMBERS_AND_RETURN_MEMBERS_EXPORT",
};
const ExportURLparam = {
    POINT_EARNINGS_EXPORT:["dateFrom","dateTo","groupBy"],
    POINT_REDEMPTIONS_EXPORT:["dateFrom","dateTo","groupBy"],
    POINT_ADJUSTMENTS_EXPORT:["dateFrom","dateTo","groupBy"],
    REWARD_REDEMPTION_EXPORT:["dateFrom","dateTo","rewardId"],
    NEW_MEMBERS_AND_RETURN_MEMBERS_EXPORT:["transactionsFrom","transactionsTo","rewardId"],
    LOCATION_BASED_REGISTRATION_EXPORT:["registrationsFrom","registrationsTo"],
    REDEMPTION_BY_MERCHANT_EXPORT:["dateFrom","dateTo"],
    ADJUSTMENT_BY_MERCHANT_EXPORT: ["dateFrom","dateTo"],
    EARNING_BY_MERCHANT_EXPORT: ["dateFrom","dateTo"],
    CARD_ACTIVATION_EXPORT:["dateFrom","dateTo"],
}
const AnalyticsExportURL = {
    POINT_EARNINGS_EXPORT : "merchants/transactions/collections/series/export",
    POINT_REDEMPTIONS_EXPORT : "merchants/transactions/redemptions/series/export",
    POINT_ADJUSTMENTS_EXPORT : "merchants/transactions/adjustments/series/export",
    REWARD_REDEMPTION_EXPORT : "rewards/redeemedrewards/series/export",
    CARD_ACTIVATION_EXPORT:"cards/activationsummary/export",
    LOCATION_BASED_REGISTRATION_EXPORT:"members/registration/counts/export",
    REDEMPTION_BY_MERCHANT_EXPORT:"merchants/transactions/redemptions/counts/export",
    ADJUSTMENT_BY_MERCHANT_EXPORT: "merchants/transactions/adjustments/counts/export",
    EARNING_BY_MERCHANT_EXPORT: "merchants/transactions/collections/counts/export",
    NEW_MEMBERS_AND_RETURN_MEMBERS_EXPORT:"members/new-return/series/export",
    AFFINITY_GROUPS: "tiers/affinitygroups/count/export",
    TIERS: "tiers/count/export",
    REWARD_LIST: "rewards/redeemedrewards/count/export",
};

const AnalyticsActiveView = {
    CARD_ANALYTICS:"CARD_ANALYTICS",
    MEMBER_ANALYTICS:"MEMBER_ANALYTICS",
    MERCHANT_ANALYTICS:"MERCHANT_ANALYTICS",
    REWARD_ANALYTICS:"REWARD_ANALYTICS",
    TIER_ANALYTICS:"TIER_ANALYTICS"
};
export {
    ChartColorCodes,
    PieChartsColorCodes,
    BarChartColorCodes,
    CardStatusFilter,
    PointAnalyticsTabs,
    MemberRegisterFilter,
    AgeGroupFilter,
    GenderFilter,
    MemberRegistrationGroupByTypes,
    MemberAffinityGroupByTypes,
    MerchantGroupByTypes,
    TransactionType,
    SubTransactionTypeHeader,
    MerchantPointHeader,
    PointAccountTypeHeader,
    PointAgeHeader,
    BarChartDataProperties,
    AnalyticsTable,
    AnalyticsTableColumns,
    AnalyticsTopListURL,
    AnalyticsExportURL,
    AnalyticsActiveExport,
    ExportURLparam,
    AnalyticsActiveView
}
