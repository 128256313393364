import React from "react";
import { LoadingComponent } from "../UtilComponents";
import "./BootstrapTableOverlay.scss";

const BootstrapTableOverlay =
  (loading) =>
  ({ children }) => {
    return (
      <div className="bs-table-overlay">
        <div className={`loading-overlay ${loading ? "active" : ""}`}>
          <LoadingComponent />
        </div>
        {children}
      </div>
    );
  };
export default BootstrapTableOverlay;
