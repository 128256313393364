import React, { useCallback, useContext, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
    SideNavigationBar,
    DropdownButton,
    DropdownItem,
    Accordion,
    Nav,
    IcIcon,
} from "@shoutout-labs/shoutout-themes-enterprise";
import {
    faBell,
    faPostcard,
    faMoneyBillStack,
    faDollarSign,
    faHistoryAlt,
    faFileImport,
    faSyncExclamation,
    faChart,
    faSlidersV,
    faSetting,
    faLockAccess,
    faShare,
    faUserAlt,
    faCoins,
    faGift,
    faPlusCircle,
    faUser,
    faStore,
    faMedal,
    faUsers,
    faGlobe,
    faSearch,
    faLock,
    faSuitcase,
} from "FaICIconMap";
import logo from "./../../assets/images/logo_full_blue.png";
import { DataContext, UserContext } from "Contexts";
import { toReplaceSpaceWithHyphen } from "Utils";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import UserBoundaryType from "../../Data/IdentityUserBoundaryType";
import NavigationLink from "./NavigationLink";

import "./Navigation.scss";

const CustomSidebarMainToggle = ({ eventKey, icon, onSelect }) => {
    const onClick = useCallback(() => {
        onSelect(eventKey);
    }, [eventKey, onSelect]);
    return (
        <SideNavigationBar.NavLink
            icon={icon}
            name={eventKey}
            onClick={onClick}
        />
    );
};

const Navigation = () => {
    const location = useLocation();
    const history = useHistory();

    const {
        organization,
        userRegions,
        onSelectRegion,
        selectedRegion: region,
        isAuthorizedForAction,
        userBoundaryType
    } = useContext(UserContext);
    const { rewards = [], isLoadingPartnerRewards } = useContext(DataContext);

    const [activeEventKey, setActiveEventKey] = useState("");

    const navList = useMemo(() => {
        const isNotAMerchantUser= userBoundaryType!==UserBoundaryType.MERCHANT;
        return [
            {
                tab: "Members",
                path: "/members",
                icon: faUserAlt,
            },
            {
                tab: "Find Members",
                path: "/find-members",
                icon: faSearch,
            },
            ...(isAuthorizedForAction(
                AccessPermissionModuleNames.POINT_RULES,
                AccessPermissionModules[AccessPermissionModuleNames.POINT_RULES]
                    .actions.ListPointRules
            )
                ? [
                      {
                          tab: "Point Rules",
                          path: "/point-rules",
                          icon: faCoins,
                      },
                  ]
                : []),
            ...(isAuthorizedForAction(
                AccessPermissionModuleNames.REWARD,
                AccessPermissionModules[AccessPermissionModuleNames.REWARD]
                    .actions.ListRewards
            )
                ? [
                      {
                          tab: "Redemptions",
                          path: "/redemptions",
                          icon: faGift,
                          secondaryLinks: [
                              {
                                  tab: "Rewards",
                                  path: "/redemptions/rewards",
                                  icon: faGift,
                              },

                              //partner rewards only have the redemption log atm
                              ...(isAuthorizedForAction(
                                  AccessPermissionModuleNames.REWARD,
                                  AccessPermissionModules[
                                      AccessPermissionModuleNames.REWARD
                                  ].actions.ListRewardRedemptionLogs
                              )
                                  ? [
                                        ...(isLoadingPartnerRewards
                                            ? [
                                                  {
                                                      tab: "Loading...",
                                                      path: "",
                                                      icon: null,
                                                  },
                                              ]
                                            : rewards.length > 0
                                            ? rewards.map((reward) => ({
                                                  tab: reward?.name,
                                                  path: `/redemptions/${toReplaceSpaceWithHyphen(
                                                      reward?.name
                                                  )}`,
                                                  icon: faGift,
                                              }))
                                            : []),
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),

            {
                tab: "Cards",
                path: "/cards",
                icon: faPostcard,
                secondaryLinks: [
                    ...(isAuthorizedForAction(
                        AccessPermissionModuleNames.CARD,
                        AccessPermissionModules[
                            AccessPermissionModuleNames.CARD
                        ].actions.ListCards
                    )
                        ? [
                              {
                                  tab: "Card Pool",
                                  path: "/cards/pool",
                                  icon: faMoneyBillStack,
                              }
                          ]
                        : []),
                    ...(isAuthorizedForAction(
                        AccessPermissionModuleNames.CARD,
                        AccessPermissionModules[
                            AccessPermissionModuleNames.CARD
                            ].actions.CreateCardBatchJob
                    ) ||
                    isAuthorizedForAction(
                        AccessPermissionModuleNames.CARD,
                        AccessPermissionModules[
                            AccessPermissionModuleNames.CARD
                            ].actions.ListCardBatchJobs
                    )
                        ? [
                            {
                                tab: "Instant Cards",
                                path: "/cards/instant-cards",
                                icon: faPlusCircle,
                            },
                            {
                                tab: "Digital Cards",
                                path: "/cards/digital-cards",
                                icon: faPlusCircle,
                            },
                            {
                                tab: "Embossed Cards",
                                path: "/cards/embossed-cards",
                                icon: faPlusCircle,
                            },
                        ]
                        : []),
                ],
            },

            {
                tab: "Tiers",
                path: "/tiers",
                icon: faMedal,
                secondaryLinks: [
                    {
                        tab: "Affinity Groups",
                        path: "/tiers/affinity_group",
                        icon: faMedal,
                    },

                    ...(isAuthorizedForAction(
                        AccessPermissionModuleNames.TIER,
                        AccessPermissionModules[
                            AccessPermissionModuleNames.TIER
                        ].actions.ListTiers
                    )
                        ? [
                              {
                                  tab: "Tiers",
                                  path: "/tiers/tier",
                                  icon: faMedal,
                              },
                          ]
                        : []),
                ],
            },
            {
                tab: "Transactions",
                path: "/transactions",
                icon: faDollarSign,
                secondaryLinks: [
                    ...(isAuthorizedForAction(
                        AccessPermissionModuleNames.TRANSACTION,
                        AccessPermissionModules[
                            AccessPermissionModuleNames.TRANSACTION
                        ].actions.ListTransactions
                    )
                        ? [
                            {
                                tab: "Transaction History",
                                path: "/transactions/history",
                                icon: faHistoryAlt,
                            },
                          ]
                        : []),
                        ...(isAuthorizedForAction(
                            AccessPermissionModuleNames.TRANSACTION,
                            AccessPermissionModules[
                                AccessPermissionModuleNames.TRANSACTION
                            ].actions.ListTransactionImportJobs
                        ) ||
                        isAuthorizedForAction(
                            AccessPermissionModuleNames.TRANSACTION,
                            AccessPermissionModules[
                                AccessPermissionModuleNames.TRANSACTION
                            ].actions.CreateTransactionImportJob
                        )
                            ? [
                                {
                                    tab: "Import Transactions",
                                    path: "/transactions/import",
                                    icon: faFileImport,
                                },
                              ]
                            : []),
                            ...(isAuthorizedForAction(
                                AccessPermissionModuleNames.TRANSACTION,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.TRANSACTION
                                ].actions.ListStagedTransaction
                            )
                            ? [
                                    {
                                        tab: "Invalid Transactions",
                                        path: "/transactions/invalid",
                                        icon: faSyncExclamation,
                                    },
                                  ]
                            : []),
                    // {
                    //     tab: "Anomaly Transactions",
                    //     path: "/transactions/anomaly",
                    //     icon: faExclamationTriangle,
                    // },
                ],
            },
            {
                tab: "Merchants",
                path: "/merchants",
                icon: faStore,
            },
            {
                tab: "Notifications",
                path: "/notifications",
                icon: faBell,
                secondaryLinks: [
                    ...(isAuthorizedForAction(
                        AccessPermissionModuleNames.NOTIFICATION,
                        AccessPermissionModules[AccessPermissionModuleNames.NOTIFICATION]
                            .actions.ListNotificationLogs
                    )
                        ? [
                            {
                                tab: "Message Log",
                                path: "/notifications/message-log",
                                icon: faHistoryAlt,
                            },
                          ]
                        : []),
                    ...(isAuthorizedForAction(
                            AccessPermissionModuleNames.NOTIFICATION,
                            AccessPermissionModules[AccessPermissionModuleNames.NOTIFICATION]
                                .actions.ListNotifications
                        )
                        ? [
                                {
                                    tab: "Templates",
                                    path: "/notifications/templates",
                                    icon: faFileImport,
                                },
                              ]
                        : []),

                ],
            },
            {
                tab: "Analytics",
                path: "/analytics",
                icon: faChart,
                secondaryLinks: [
                    {
                        tab: "Members",
                        path: "/analytics/members",
                        icon: faUsers,
                    },
                    {
                        tab: "Points",
                        path: "/analytics/points",
                        icon: faCoins,
                    },
                    {
                        tab: "Rewards",
                        path: "/analytics/rewards",
                        icon: faGift,
                    },
                    {
                        tab: "Cards",
                        path: "/analytics/cards",
                        icon: faPostcard,
                    },
                    {
                        tab: "Tiers",
                        path: "/analytics/tiers",
                        icon: faStore,
                    },
                    {
                        tab: "Merchants",
                        path: "/analytics/merchants",
                        icon: faStore,
                    },
                ],
            },
            {
                tab: "Fraudulence",
                path: "/fraudulence",
                icon: faLock,
                secondaryLinks: [
                    {
                        tab: "Fraud",
                        path: "/fraudulence/fraud",
                        icon: faUsers,
                    },
                    {
                        tab: "Audit Log",
                        path: "/fraudulence/auditLog",
                        icon: faCoins,
                    }
                ],
            },
            {
                tab: "Jobs",
                path: "/jobs",
                icon: faSuitcase,
            },
            {
                tab: "Control Panel",
                path: "/control-panel",
                icon: faSlidersV,
                secondaryLinks: [
                    ...(isNotAMerchantUser?[{
                        tab: "Settings",
                        path: "/control-panel/settings/members",
                        icon: faSetting,
                    }]:[]),
                    {
                        tab: "Access Control",
                        path: "/control-panel/access-control",
                        icon: faLockAccess,
                    },
                    {
                        tab: "Charity List",
                        path: "/control-panel/charity-list",
                        icon: faShare,
                    },
                ],
            },
            {
                tab: "Profile",
                path: "/profile",
                icon: faUser,
            },
        ];
    }, [userBoundaryType,isAuthorizedForAction, rewards, isLoadingPartnerRewards]);

    // const region = useMemo(() => {
    //   return organization.regions.find((item) => item._id === regionId) || null;
    // }, [organization, regionId]);

    const navigateTo = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(e.currentTarget.getAttribute("href"));
        },
        [history]
    );

    const onSetActiveEventKey = useCallback(
        (eventKey) => {
            setActiveEventKey(eventKey !== activeEventKey ? eventKey : "");
        },
        [setActiveEventKey, activeEventKey]
    );

    const isGlobalSettings = useMemo(() => {
        if (location.pathname.includes("/control-panel/")) {
            return true;
        }
        return false;
    }, [location]);

    return (
        // <div className="sidebar-wrapper bg-white">
        <div className="sidebar-wrapper">
            <SideNavigationBar
                className="side-navigation"
                logo={organization.organizationLogoImageUrl || logo}
            >
                {/* <div className="sidebar-container"> */}
                {/* <div className="side-navigation-nav-link pt-1">
            <Image className="logo" src={logo} alt="ShoutOUT" />
          </div> */}

                {isGlobalSettings ? (
                    <DropdownButton
                        data-testid="region-selection-dropdown"
                        bsPrefix="btn w-100 region-selection-btn mb-3"
                        title={
                            <div>
                                <IcIcon
                                    icon={faGlobe}
                                    className="mr-2"
                                    size="lg"
                                />
                                Global
                            </div>
                        }
                        disabled
                    />
                ) : (
                    <DropdownButton
                        data-testid="region-selection-dropdown"
                        bsPrefix="btn w-100 region-selection-btn mb-3"
                        title={
                            region && (
                                <>
                                    {region.regionIconUrl && (
                                        <img
                                            src={region.regionIconUrl}
                                            className="flags-img  rounded-circle mr-1 mb-1"
                                            alt={region.regionName}
                                        />
                                    )}
                                    <span className="mr-1">
                                        {region.regionName}
                                    </span>
                                </>
                            )
                        }
                        className=""
                        onSelect={onSelectRegion}
                    >
                        {userRegions.map(
                            ({ _id, regionIconUrl, regionName }) => {
                                return (
                                    <DropdownItem
                                        eventKey={_id}
                                        key={_id}
                                        className="region-selection"
                                    >
                                        {region.regionIconUrl && (
                                            <img
                                                src={regionIconUrl}
                                                className="flags-img rounded-circle  mr-2"
                                                alt={regionName}
                                            />
                                        )}
                                        {regionName}
                                    </DropdownItem>
                                );
                            }
                        )}
                    </DropdownButton>
                )}
                {/* <NavLink
            to="/"
            className={`link dashboard text-${
              location.pathname === "/" ? "primary" : "dark"
            } `}
            activeClassName={location.pathname === "/" ? "active-link" : ""}
            id="side-nav-link-dashboard"
          >
            <IcIcon icon={faTachometerAlt} className="mx-1" />
            <span className="link-text">Dashboard</span>
          </NavLink> */}
                <Accordion activeKey={activeEventKey}>
                    {navList.map((item) => {
                        /* switch(item.tab) {
              case "Cards":
                return (
                    <NavigationLink
                        path={item.path}
                        tab={item.tab}
                        icon={item.icon}
                        activePath={location.pathname}
                    />
                );
                break;
              default:
                return (
                    <NavigationLink
                        path={item.path}
                        tab={item.tab}
                        icon={item.icon}
                        activePath={location.pathname}
                    />
                );
            }

            */

                        if (item.secondaryLinks) {
                            return (
                                <div className="secondary-menu">
                                    <CustomSidebarMainToggle
                                        eventKey={item.tab}
                                        onSelect={onSetActiveEventKey}
                                        icon={item.icon}
                                        activeKey={activeEventKey}
                                    />
                                    <Accordion.Collapse eventKey={item.tab}>
                                        <div>
                                            {item.secondaryLinks.map(
                                                ({ tab, path }) => (
                                                    <Nav.Item key={path}>
                                                        <Nav.Link
                                                            href={path}
                                                            active={
                                                                location.pathname ===
                                                                path
                                                            }
                                                            onClick={navigateTo}
                                                            disabled={
                                                                item?.tab ===
                                                                    "Redemptions" &&
                                                                tab ===
                                                                    "Loading..." &&
                                                                isLoadingPartnerRewards &&
                                                                true
                                                            }
                                                        >
                                                            {tab}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )
                                            )}
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            );
                        }
                        return (
                            <NavigationLink
                                path={item.path}
                                tab={item.tab}
                                icon={item.icon}
                                activePath={location.pathname}
                                key={item.path}
                            />
                        );
                    })}
                    {/* </div> */}
                </Accordion>
            </SideNavigationBar>
        </div>
    );
};

export default Navigation;
