import React from "react";

const UnauthorizedAccessMessage = ({ text }) => {
    return (
        <h4 className="text-center text-danger">
            {text || "You are not authorized to view this content"}
        </h4>
    );
};

export { UnauthorizedAccessMessage };
