import {
    fetchGet,
    fetchPost,
    fetchPut,
    jsonToQueryParam,
    fetchDelete,
} from "./CommonServiceUtils";

import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;
const getMerchantList = ({
    limit = 100,
    skip = 0,
    regionId,
    searchKey,
    type,
    status,
}) => {
    return fetchGet(
        `${BASE_URL}merchants?${jsonToQueryParam({
            limit,
            skip: skip,
            regionId: regionId,
            searchKey,
            type,
            status,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MERCHANT,
            AccessPermissionModules[AccessPermissionModuleNames.MERCHANT]
                .actions.ListMerchants
        )
    );
};
const archiveMerchant = (id) => {
    return fetchDelete(
        `${BASE_URL}merchants/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MERCHANT,
            AccessPermissionModules[AccessPermissionModuleNames.MERCHANT]
                .actions.DeleteMerchant
        )
    );
};
const createNewMerchant = (merchantDetails) => {
    return fetchPost(
        `${BASE_URL}merchants`,
        merchantDetails,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MERCHANT,
            AccessPermissionModules[AccessPermissionModuleNames.MERCHANT]
                .actions.CreateMerchant
        )
    );
};

const getMerchantById = (merchantId) => {
    return fetchGet(
        `${BASE_URL}merchants/${merchantId}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MERCHANT,
            AccessPermissionModules[AccessPermissionModuleNames.MERCHANT]
                .actions.GetMerchant
        )
    );
};

const updateMerchant = (id, payload) => {
    return fetchPut(
        `${BASE_URL}merchants/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MERCHANT,
            AccessPermissionModules[AccessPermissionModuleNames.MERCHANT]
                .actions.UpdateMerchant
        )
    );
};

const getAllMerchants = (args) => {
    return new Promise(async (resolve, reject) => {
        const limit = 500;
        let totalCount = 0,
            merchants = [];
        try {
            do {
                const merchantResponse = await getMerchantList({
                    limit,
                    skip: merchants.length,
                    ...args,
                });
                totalCount = merchantResponse.total;
                merchants = [...merchants, ...merchantResponse.items];
            } while (merchants.length < totalCount);
            resolve(merchants);
        } catch (e) {
            reject(e);
        }
    });
};
export {
    getMerchantList,
    createNewMerchant,
    getMerchantById,
    updateMerchant,
    archiveMerchant,
    getAllMerchants,
};
