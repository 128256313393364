import moment from "moment";
import { convertArrayToObject } from "Components/merchants/utils/MerchantUtility";

const MerchantTypes = [
    {
        value: "INTERNAL",
        name: "Internal",
    },
    {
        value: "EXTERNAL",
        name: "External",
    }
];
const MerchantProfileTabs = {
    REPORT : "REPORT",
    INTEGRATIONS : "INTEGRATIONS",
    CONTACT_DETAILS : "CONTACT_DETAILS",
    USERS: "USERS",
    LOCATIONS: "LOCATIONS",
    TRANSACTIONS : "TRANSACTIONS"
}
const MerchantStatus = [
    {
        value: "DRAFT",
        name: "Draft",
    },
    {
        value: "ACTIVE",
        name: "Active",
    },
    {
        value: "SUSPENDED",
        name: "Suspended",
    },
];
const NearestLocationStatus = [
    {
        value: true,
        name: "Yes",
    },
    {
        value: false,
        name: "No",
    }
];

const MerchantOptions = [
    {
        value: "adjustPoints",
        name: "Adjust Points",
    },
    {
        value: "enroll",
        name: "Enroll",
    },
    {
        value: "earn",
        name: "Earn",
    },
    {
        value: "redeemPoints",
        name: "Redeem Points",
    },
    {
        value: "redeemRewards",
        name: "Redeem Rewards",
    },
    {
        value: "refund",
        name: "Refund",
    },
    {
        value: "void",
        name: "Void",
    },
    {
        value: "claimReward",
        name: "Claim Reward",
    }
];

const MerchantPickUpLocationOptions = [
    {
        value: "TRUE",
        name: "true",
    },
    {
        value: "FALSE",
        name: "false",
    }
];

const LocationEnableForTransactionOptions = [
    {
        value: true,
        name: "True",
    },
    {
        value: false,
        name: "False",
    }
];

const MerchantLocationStatus = [
    {
        value: "DRAFT",
        name: "Draft",
    },
    {
        value: "ACTIVE",
        name: "Active",
    },
    {
        value: "SUSPENDED",
        name: "Suspended",
    }
]

const MerchantTransactionFilter = [
    {
        value: "LOCATION",
        name: "Location"
    },
    {
        value: "TRANSACTION_TYPE",
        name: "Transaction Type"
    },

];

const MerchantTransactionType = [
    {
        value: "COLLECTION",
        name: "Collection",
    },
    {
        value: "REDEMPTION",
        name: "Redemption",
    },
    {
        value: "ADJUSTMENT",
        name: "Adjustment",
    }
]

const MerchantTypesObj = convertArrayToObject(MerchantTypes, "value", null);
const MerchantStatusObj = convertArrayToObject(MerchantStatus, "value", null);
const MerchantLocationStatusObj = convertArrayToObject(MerchantLocationStatus, "value", null);
const MerchantTransactionTypeObj = convertArrayToObject(MerchantTransactionType, "value", null);
const MerchantLocationPickUpObj = convertArrayToObject(MerchantPickUpLocationOptions, "name", null);

const MerchantTypeColorCode = {
    [MerchantTypesObj.EXTERNAL]: "info",
    [MerchantTypesObj.INTERNAL]: "secondary",
};

const MerchantTypeTextColorCode = {
    [MerchantTypesObj.EXTERNAL]: "text-info",
    [MerchantTypesObj.INTERNAL]: "text-secondary",
};

const MerchantStatusColorCode = {
    [MerchantStatusObj.DRAFT]: "dark",
    [MerchantStatusObj.ACTIVE]:  "success",
    [MerchantStatusObj.SUSPENDED]: "danger",
};

const NearestLocationStatusColorCode = {
    [false]: "dark",
    [true]:  "success",
};

const NearestLocationStatusTextColorCode = {
    [false]: "text-dark",
    [true]:  "text-success",
};

const MerchantStatusTextColorCode = {
    [MerchantStatusObj.DRAFT]: "text-dark",
    [MerchantStatusObj.ACTIVE]:  "text-success",
    [MerchantStatusObj.SUSPENDED]: "text-danger",
};

const MerchantLocationStatusColorCode = {
    [MerchantLocationStatusObj.DRAFT]: "dark",
    [MerchantLocationStatusObj.ACTIVE]:  "success",
    [MerchantLocationStatusObj.SUSPENDED]:  "danger",
};

const MerchantLMerchantTransactionTypeColorCode = {
    [MerchantTransactionTypeObj.ADJUSTMENT]: { color: "white", backgroundColor: "#8a00e6" },
    [MerchantTransactionTypeObj.COLLECTION]: { color: "white", backgroundColor: "#21b73f" },
    [MerchantTransactionTypeObj.REDEMPTION]: { color: "white", backgroundColor: "#fd7e14" },
};

const DatePeriods = {
    "7 Days": {
        toDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
        fromDate: moment().subtract(7, "day").format("YYYY-MM-DD"),
        selectedTimePeriod : "prev. 7 days"
    },
    "28 Days": {
        toDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
        fromDate: moment().subtract(28, "day").format("YYYY-MM-DD"),
        selectedTimePeriod : "prev. 28 days"
    },
    "90 Days": {
        toDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
        fromDate: moment().subtract(90, "day").format("YYYY-MM-DD"),
        selectedTimePeriod : "prev. 90 days"
    },
    "365 Days": {
        toDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
        fromDate: moment().subtract(365, "day").format("YYYY-MM-DD"),
        selectedTimePeriod : "prev. 365 days"
    }
  };

export {
    MerchantTypes,
    MerchantStatus,
    MerchantOptions,
    MerchantPickUpLocationOptions,
    MerchantLocationStatus,
    MerchantLocationStatusObj,
    MerchantStatusObj,
    MerchantLocationPickUpObj,
    MerchantTypeColorCode,
    MerchantTypeTextColorCode,
    MerchantStatusColorCode,
    MerchantStatusTextColorCode,
    MerchantLocationStatusColorCode,
    MerchantTransactionFilter,
    MerchantTransactionType,
    MerchantLMerchantTransactionTypeColorCode,
    MerchantProfileTabs,
    DatePeriods,
    NearestLocationStatus,
    NearestLocationStatusColorCode,
    NearestLocationStatusTextColorCode,
    LocationEnableForTransactionOptions
};
