import React from 'react'
import {IcIcon} from "@shoutout-labs/shoutout-themes-enterprise";
import {toTitleCase} from "../../../../Utils";

const NameIconTemplate=({ name, icon })=>{
        return(<>
            {icon && <IcIcon icon={icon} className='mr-2 text-black' />}
            {toTitleCase(name)}
        </>)
}

export default NameIconTemplate
