import { fetchGet, fetchPost, jsonToQueryParam, fetchDelete, fetchPut } from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules,AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = process.env.REACT_APP_BUILD_VARIANT === 'dev' ?  `${Constants.BASE_URL_LOYALTY}anomalyservice/` : `${Constants.BASE_URL}anomalyservice/`;
//const BASE_URL = "https://loyaltybeta.api.topnotch.club/api/anomalyservice/"

/*Rules*/

const getRulesList = (queryObj) => {
    return fetchGet(`${BASE_URL}rules?${jsonToQueryParam(queryObj)}`,true,getPermissionPath(
        AccessPermissionModuleNames.RULE,
        AccessPermissionModules[AccessPermissionModuleNames.RULE].actions.ListRules
    ));
};

const getRulesListById =(id)=> {
    return fetchGet(`${BASE_URL}rules/${id}`,true,getPermissionPath(
        AccessPermissionModuleNames.RULE,
        AccessPermissionModules[AccessPermissionModuleNames.RULE].actions.GetRule
    ));
};

const createRule = (queryObj) => {
    return fetchPost(
        `${BASE_URL}rules`, queryObj,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.RULE,
            AccessPermissionModules[AccessPermissionModuleNames.RULE].actions
                .CreateRule
        )
    );
};

const deleteRule = (id) => {
    return fetchDelete(
        `${BASE_URL}rules/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.RULE,
            AccessPermissionModules[AccessPermissionModuleNames.RULE].actions
                .DeleteRule
        )
    );
};

const updateRule = (id, payload) => {
    return fetchPut(
        `${BASE_URL}rules/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.RULE,
            AccessPermissionModules[AccessPermissionModuleNames.RULE]
                .actions.UpdateRule
        )
    );
};

/*Rule Types */

const getRuleTypes = (queryObj) => {
    return fetchGet(`${BASE_URL}ruletypes?${jsonToQueryParam(queryObj)}`,true,getPermissionPath(
        AccessPermissionModuleNames.RULE_TYPE,
        AccessPermissionModules[AccessPermissionModuleNames.RULE_TYPE].actions.ListRuleTypes
    ));
};

const getFraudIncidents = (queryObj) => {
    return fetchGet(`${BASE_URL}incidents?${jsonToQueryParam(queryObj)}`,true,getPermissionPath(
        AccessPermissionModuleNames.INCIDENT,
        AccessPermissionModules[AccessPermissionModuleNames.INCIDENT].actions.ListIncidents
    ));
};

const getFraudIncidentById =(id)=> {
    return fetchGet(`${BASE_URL}incidents/${id}`,true,getPermissionPath(
        AccessPermissionModuleNames.INCIDENT,
        AccessPermissionModules[AccessPermissionModuleNames.INCIDENT].actions.GetIncident
    ));
};

const getFraudIncidentTransactions = (queryObj) => {
    return fetchGet(`${BASE_URL}incidents/transactions?${jsonToQueryParam(queryObj)}`,true,getPermissionPath(
        AccessPermissionModuleNames.INCIDENT,
        AccessPermissionModules[AccessPermissionModuleNames.INCIDENT].actions.GetIncident
    ));
};

const updateFraudIncidentById = (id, payload) => {
    return fetchPut(
        `${BASE_URL}incidents/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.INCIDENT,
            AccessPermissionModules[AccessPermissionModuleNames.INCIDENT]
                .actions.UpdateIncident
        )
    );
};

const exportListOfIncidents = (queryObj) => {
    return fetchGet(`${BASE_URL}incidents/export?${jsonToQueryParam(queryObj)}`,true,getPermissionPath(
        AccessPermissionModuleNames.INCIDENT,
        AccessPermissionModules[AccessPermissionModuleNames.INCIDENT].actions.ExportIncidents
    ));
};


const exportListOfTransactions = ({incidentId}) => {
    return fetchGet(`${BASE_URL}incidents/transactions/export?${jsonToQueryParam({incidentId})}`,true,getPermissionPath(
        AccessPermissionModuleNames.INCIDENT,
        AccessPermissionModules[AccessPermissionModuleNames.INCIDENT].actions.ExportIncident
    ));
};

export {
    getRulesList,
    updateRule,
    deleteRule,
    createRule,
    getRuleTypes,
    getFraudIncidents,
    getFraudIncidentById,
    updateFraudIncidentById,
    getRulesListById,
    getFraudIncidentTransactions,
    exportListOfTransactions,
    exportListOfIncidents
};
