import React, { useReducer, useEffect, useCallback, useContext } from "react";
import { getIdentityUsers, getMerchantList } from '../Services';
import { UserContext } from './userContext';
import { AccessPermissionModuleNames, AccessPermissionModules } from "../Data";

const IdentityUsersContext = React.createContext()

const defaultLimit = 25, defaultSkip = 1;

const resetIdUserStatus = {
    usersList: [],
    merchantsList: [],
    limit: defaultLimit,
    skip: 1,
    isLoading: false,
    totalCount: 0,
};

const initialState = {
    selectedFilters: [],
    searchText: '',
    regionId: '',
    merchantId: '',
    ...resetIdUserStatus
};

const IdentityUsersContextActions = {
    SET_ID_USERS: 'setIdUsers',
    SET_MERCHANTS: 'setMerchants',
    SET_IS_LOADING: 'setIsLoading',
    ADD_USER:"addUser",
    UPDATE_ID_USERS: 'updateIdUser',
    ADD_FILTER: 'addFilter',
    REMOVE_SELECTED_FILTER: 'removeSelectedFilter',
    SEARCHING: 'idUserSearching',
    CHANGE_SEARCH_TEXT: 'changeSearchText',
    SET_PAGINATION_INFO:"setPaginationInfo",
    MEMBER_DATA_RESET : "memberDataReset"
};

const reducer = (state, action) => {
    switch (action.type) {
        case IdentityUsersContextActions.SET_ID_USERS: {
            return {
                ...state,
                usersList: action.users.items,
                isLoading: false,
                totalCount: action.users.total,
            };
        }

        case IdentityUsersContextActions.SET_MERCHANTS: {
            return {
                ...state,
                merchantsList: [...state.merchantsList, ...action.merchants],
                isLoading: false,
            };
        }

        case IdentityUsersContextActions.ADD_USER:{
            return {
                ...state,
                usersList:[action.user,...state.usersList]
            }
        }
        case IdentityUsersContextActions.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.status
            }
        }
        case IdentityUsersContextActions.UPDATE_ID_USERS: {
            return {
              ...state,
              usersList: state.usersList.map((item) => {
                if (item._id === action.user._id) {
                  return action.user;
                }
                return item;
              }),
            };
        }
        case IdentityUsersContextActions.ADD_FILTER: {
            return {
                ...state,
                regionId: action.regionId,
                merchantId: action.merchantId,
                ...resetIdUserStatus
            }
        }
        case IdentityUsersContextActions.REMOVE_SELECTED_FILTER: {
            let selectedFilters = [...state.selectedFilters];
            if (action.index) {
                selectedFilters.splice(action.index, 1);
            } else {
                selectedFilters = [];
            }
            return {
                ...state,
                selectedFilters,
                skip: 0,
                usersList: [],
                isLoading: true
            }
        }
        case IdentityUsersContextActions.CHANGE_SEARCH_TEXT: {
            return {
                ...state,
                searchText: action.searchText,
                ...resetIdUserStatus
            }
        }
        case IdentityUsersContextActions.SET_PAGINATION_INFO: {
            return {
                ...state,
                skip:action.skip?action.skip:1,
                limit:action.limit?action.limit:state.limit,
                isLoading: true
            }
        }
        case IdentityUsersContextActions.MEMBER_DATA_RESET: {
            return {
                ...state,
                usersList: [],
                limit:action.limit,
                skip:action.skip,
                isLoading: true
            }
        }
        default:
            return state;
    }
};

let searchTimeout;

const IdentityUsersContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isAuth ,selectedRegion,isAuthorizedForAction} = useContext(UserContext);

    const loadIdUsers = useCallback(async (newSearchText) => {
        if (
            isAuthorizedForAction(
                AccessPermissionModuleNames.USERS,
                AccessPermissionModules[AccessPermissionModuleNames.USERS]
                    .actions.ListUsers
            )
        ) {
            try {
                dispatch({ type: IdentityUsersContextActions.SET_IS_LOADING, status: true });
                const contactResponse = await getIdentityUsers({
                    limit:state.limit,
                    skip:(state.skip-1)*state.limit,
                    searchKey: newSearchText || state.searchText,
                    regionId: state.regionId,
                    merchantId: state.merchantId
                });
                dispatch({
                    type: IdentityUsersContextActions.SET_ID_USERS,
                    users: contactResponse.data
                });
            } catch (e) {
                console.error(e);
                dispatch({ type: IdentityUsersContextActions.SET_IS_LOADING, status: false });
            }
        }
    }, [dispatch,isAuthorizedForAction, state.skip, state.limit, state.searchText, state.regionId, state.merchantId]);

    const loadMerchants = useCallback(async () => {
        if (
            isAuthorizedForAction(
                AccessPermissionModuleNames.MERCHANT,
                AccessPermissionModules[AccessPermissionModuleNames.MERCHANT]
                    .actions.ListMerchants
            )
        ) {
            try {
                dispatch({ type: IdentityUsersContextActions.SET_IS_LOADING, status: true });
                const merchantResponse = await getMerchantList({
                    skip: defaultSkip,
                    limit: defaultLimit,
                    regionId:selectedRegion._id
                });
                dispatch({
                    type: IdentityUsersContextActions.SET_MERCHANTS,
                    merchants: merchantResponse.items
                });
            } catch (e) {
                console.error(e);
                dispatch({ type: IdentityUsersContextActions.SET_IS_LOADING, status: false });
            }
        }
    }, [isAuthorizedForAction,dispatch,selectedRegion]);

    const resetIdUser = useCallback(async ({skip, pageReset, limit})=>{
        if(pageReset) {
            await dispatch({
                type: IdentityUsersContextActions.MEMBER_DATA_RESET,
                skip: skip,
                limit: limit
            });
        }
    },[dispatch]);

    const onChangePagination = useCallback(async ({ skip, limit }) => {
            await dispatch({
                type: IdentityUsersContextActions.SET_PAGINATION_INFO,
                skip: skip,
                limit: limit
            });
    },[dispatch]);

    const onChangeSearchText = useCallback(searchText => {
          dispatch({
            type: IdentityUsersContextActions.CHANGE_SEARCH_TEXT,
            searchText,
          });
    }, [dispatch]);

    const onSetFilters = useCallback((regionId, merchantId) => {
        dispatch({
          type: IdentityUsersContextActions.ADD_FILTER,
          regionId,
          merchantId
        });
  }, [dispatch]);

  const addUserToBeginning=useCallback((user)=>{
    dispatch({
        type:IdentityUsersContextActions.ADD_USER,
        user
    })
  },[dispatch])

    useEffect(() => {
        if (isAuth) {
            loadIdUsers();
            loadMerchants();
        }
        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth, state.searchText, state.regionId, state.merchantId,state.limit,state.skip]);

    const value = {
        ...state,
        resetIdUser,
        onChangePagination,
        onChangeSearchText,
        loadIdUsers,
        onSetFilters,
        addUserToBeginning
    };
    return (
        <IdentityUsersContext.Provider value={value}>
            {props.children}
        </IdentityUsersContext.Provider>
    );
}

const IdentityUserContext = IdentityUsersContext.Consumer;

export { IdentityUsersContext, IdentityUsersContextProvider, IdentityUserContext };
