const CardTypes = {
  DIGITAL: "DIGITAL_CARD",
  INSTANT: "INSTANT_CARD",
  EMBOSSED: "EMBOSSED_CARD",
  KEY_TAG: "KEY_TAG",
  REGULAR: "REGULAR_CARD",
  REGULAR_CARD_AND_KEY_TAG: "REGULAR_CARD_AND_KEY_TAG",
};

export default CardTypes;
