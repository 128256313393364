import moment from "moment";

const SettingsReportmonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const TimeOfDayValues = ["AM", "PM"];

const formatToCommonReadableFormat = (date) => {
    return moment(date).format("MMM DD, YYYY LT");
};

const formatToCommonReadableFormatDateOnly = (date) =>
    moment(date).format("MMM DD, YYYY");

const formatToCommonFormat = (date) => {
    return moment(date).format("YYYY-MM-DD");
};

const getSuffixForDayOfMonth = (day) => {
    const dayType1 = Number(day) % 10,
        dayType2 = Number(day) % 100;

    if (dayType1 === 1 && dayType2 !== 11) {
        return `${day}st`;
    }

    if (dayType1 === 2 && dayType2 !== 12) {
        return `${day}nd`;
    }

    if (dayType1 === 3 && dayType2 !== 13) {
        return `${day}rd`;
    }

    return `${day}th`;
};

const convertTimeFrom12HTo24H = (time) =>
    moment(time || "", ["HH:mm A"])
        .format("HH:mm")
        .toString();

const convertTimeFrom24HTo12H = (time) =>
    moment(time || "", ["HH:mm"])
        .format("hh:mm A")
        .toString();

// * Jobs related
const getNextExecutionDate = ({
    daily = "",
    isEndOfMonth = false,
    dayOfMonth = null,
}) => {
    if (daily) {
        const executionTimeToDate = moment(daily, ["HH:mm A"]);
        const nextExecution =
            moment(executionTimeToDate, ["HH:mm A"]).diff(
                moment(),
                "minutes"
            ) <= 0
                ? executionTimeToDate.add(1, "d")
                : executionTimeToDate;

        return {
            nextDate: formatToCommonReadableFormat(nextExecution),
            executionMonth: null,
        };
    } else {
        if (isEndOfMonth) {
            if (moment().diff(moment().endOf("M")) === 0) {
                return {
                    nextDate: formatToCommonReadableFormat(
                        moment().add(1, "M").endOf("M").startOf("D")
                    ),
                    executionMonth: null,
                };
            } else {
                return {
                    nextDate: formatToCommonReadableFormat(
                        moment().endOf("M").startOf("D")
                    ),
                    executionMonth: null,
                };
            }
        } else {
            const dateOfMonth = moment().date(dayOfMonth);
            const nextMonth = moment().add(1, "M");
            if (dateOfMonth.startOf("D").diff(moment().startOf("D")) <= 0) {
                if (
                    dayOfMonth >= nextMonth.startOf("M").date() &&
                    dayOfMonth <= nextMonth.endOf("M").date()
                ) {
                    return {
                        nextDate: formatToCommonReadableFormat(
                            dateOfMonth.add(1, "M").startOf("D")
                        ),
                        executionMonth: dateOfMonth
                            .add(1, "M")
                            .startOf("D")
                            .format("MMMM"),
                    };
                } else {
                    return {
                        nextDate: null,
                        executionMonth: dateOfMonth
                            .add(1, "M")
                            .startOf("D")
                            .format("MMMM"),
                    };
                }
            } else {
                if (
                    dayOfMonth >= moment().startOf("M").date() &&
                    dayOfMonth <= moment().endOf("M").date()
                ) {
                    return {
                        nextDate: formatToCommonReadableFormat(
                            dateOfMonth.startOf("D")
                        ),
                        executionMonth: dateOfMonth.startOf("D").format("MMMM"),
                    };
                } else {
                    return {
                        nextDate: null,
                        executionMonth: dateOfMonth.startOf("D").format("MMMM"),
                    };
                }
            }
        }
    }
};
// * !Jobs related

export {
    SettingsReportmonths,
    TimeOfDayValues,
    formatToCommonReadableFormat,
    formatToCommonFormat,
    formatToCommonReadableFormatDateOnly,
    getSuffixForDayOfMonth,
    getNextExecutionDate,
    convertTimeFrom12HTo24H,
    convertTimeFrom24HTo12H,
};
