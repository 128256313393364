import {
    fetchGet,
    fetchPut,
    fetchPost,
    fetchDelete,
    fetchPostMultipart,
    jsonToQueryParam,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

/* Rewards */
const getRewards = (queryObj) => {
    //Query params : limit, skip, status, type, portalVisibility, searchKey
    return fetchGet(
        `${BASE_URL}rewards?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .ListRewards
        )
    );
};

const getRewardById = (rewardId) => {
    return fetchGet(
        `${BASE_URL}rewards/${rewardId}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .GetReward
        )
    );
};

const createReward = (rewardPayload) => {
    return fetchPost(
        `${BASE_URL}rewards`,
        rewardPayload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .CreateReward
        )
    );
};

const updateRewardById = (rewardId, payload) => {
    return fetchPut(
        `${BASE_URL}rewards/${rewardId}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .UpdateReward
        )
    );
};

const deleteReward = (rewardId) => {
    return fetchDelete(
        `${BASE_URL}rewards/${rewardId}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .DeleteReward
        )
    );
};

const topUpReward = (topUpPayload) => {
    return fetchPost(
        `${BASE_URL}rewardtopups/manual`,
        topUpPayload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .CreateRewardTopup
        )
    );
};

const topUpRewardFile = (rewardId, fileData) => {
    const formData = new FormData();
    formData.append("file", fileData);
    return fetchPostMultipart(
        `${BASE_URL}rewardtopups/topupfile?${jsonToQueryParam(rewardId)}`,
        formData,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .UploadRewardTopupFile
        )
    );
};

const redeemRewards = (paylaod) => {
    return fetchPost(
        `${BASE_URL}rewardredeem`,
        paylaod,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .RedeemReward
        )
    );
};

const getTopupLogs = (queryObj) => {
    //Query params :
    // limit,
    // skip,
    // regionId,
    // rewardId,
    // method,
    // status,
    return fetchGet(
        `${BASE_URL}rewardtopups?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .ListRewardTopups
        )
    );
};

const getRedemptionLogs = (queryObj) => {
    //Query params :
    // limit,
    // skip,
    // loyaltyId,
    // rewardId,
    // distributionJobId,
    // rewardSubType,
    // fromDate,
    // toDate,
    // searchKey - Not there in API schema
    return fetchGet(
        `${BASE_URL}redemptionlogs?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .ListRewardRedemptionLogs
        )
    );
};

const getRedemptionLogsById = (payload) => {
    return fetchGet(
        `${BASE_URL}redemptionlogs/${payload._id}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .GetRewardRedemptionLog
        )
    );
};

const updateRewardRedemptionLog = (_id, payload) => {
    return fetchPut(
        `${BASE_URL}redemptionlogs/${_id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .UpdateLogItem
        )
    );
};

const cancelReward = (_id, payload) => {
    return fetchPut(
        `${BASE_URL}redemptionlogs/cancel/${_id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .CancelLogItem
        )
    );
};

const refundRedemptionPoints = (_id, payload) => {
    return fetchPut(
        `${BASE_URL}redemptionlogs/refund/${_id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .RefundLogItem
        )
    );
};

const updatePartnerRedemptionLog = (_id, payload) => {
    return fetchPut(
        `${BASE_URL}redemptionlogs/partnerrewards/${_id}`,
        payload,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .UpdateLogItem
        )
    );
};

const bulkRefundPartnerRewardRedemptions = (payload) => {
    return fetchPut(
        `${BASE_URL}redemptionlogs/partnerrewards/bulkrefund`,
        payload,
        true
        /* // TODO: Add permissions.
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .RefundLogItem
        ) */
    );
};

const reprocessRedemptionLogs = (_id, payload) => {
    return fetchPut(
        `${BASE_URL}redemptionlogs/reprocess/${_id}`,
        payload
        /*TODO:ADD API PERMISSION*/
        /*   true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .RefundLogItem
        )*/
    );
};

const redeemReward = (payload) => {
    return fetchPost(
        `${BASE_URL}rewardredeem`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .RedeemReward
        )
    );
};

const claimReward = (payload) => {
    return fetchPost(
        `${BASE_URL}klip/claimreward`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.REWARD,
            AccessPermissionModules[AccessPermissionModuleNames.REWARD].actions
                .ClaimReward
        )
    );
};

export {
    getRewards,
    getRewardById,
    createReward,
    updateRewardById,
    deleteReward,
    topUpReward,
    topUpRewardFile,
    redeemRewards,
    getRedemptionLogs,
    updateRewardRedemptionLog,
    refundRedemptionPoints,
    redeemReward,
    claimReward,
    getTopupLogs,
    cancelReward,
    getRedemptionLogsById,
    reprocessRedemptionLogs,
    bulkRefundPartnerRewardRedemptions,
    updatePartnerRedemptionLog,
};
