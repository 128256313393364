import React, { useCallback } from "react";
import {
  SideNavigationBar,
} from "@shoutout-labs/shoutout-themes-enterprise";
import { useHistory } from "react-router-dom";
// import "./Navigation.scss";

const NavigationLink = ({ path, tab, icon, activePath, onclick }) => {
  const history = useHistory();
  const onClickTab = useCallback(() => {
    if (onclick) {
      onclick(path);
    } else {
      history.push(path);
    }
  }, [history, path, onclick]);
  return (
    <>
      <SideNavigationBar.NavLink
        active={activePath===path}
        icon={icon}
        id="side-nav-link-dashboard"
        onClick={onClickTab}
        name={tab}
      >
        {/* <NavLink
          to={path}
          className={`link text-${
            activePath.startsWith(path) ? "primary" : "dark"
          } `}
          activeClassName={activePath.startsWith(path) ? "active-link" : ""}
          id="side-nav-link-dashboard"
          data-testid="nav-item"
          onClick={onclick}
        >
          <IcIcon icon={icon} className="mx-1" data-tetsid="nav-icon" />
          <span className="link-text" data-testid="nav-name">
            {tab}
          </span>
        </NavLink> */}
      </SideNavigationBar.NavLink>
    </>
  );
};

export default NavigationLink;
