import {
  fetchGet,
  fetchPost,
  jsonToQueryParam,
/*  fetchPostMultipart,*/
  fetchDelete
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules,AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}coreservice/`;
// const BASE_URL = 'https://7hmi05mklj.execute-api.us-east-1.amazonaws.com/';

/*
const uploadImage = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return fetchPostMultipart(`${BASE_URL}imageuploads`, formData);
};
*/

const getContactAttributes = (regionId, skip = 0, limit = 1000) => {
  return fetchGet(`${BASE_URL}attributes?${jsonToQueryParam({ regionId,skip,limit })}`,true,getPermissionPath(
    AccessPermissionModuleNames.ATTRIBUTE,
    AccessPermissionModules[AccessPermissionModuleNames.ATTRIBUTE].actions
        .ListAttributes
));
};

const getSegmentCategories = (regionId,skip = 0, limit = 1000) => {
  return fetchGet(
    `${BASE_URL}segmentcategories?${jsonToQueryParam({ regionId,limit,skip })}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.SEGMENT,
      AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
          .ListSegmentCaregories
    )
  );
};

const createSegmentCategory = ({ name, regionId, iconUrl }) => {
  return fetchPost(
      `${BASE_URL}segmentcategories`, { name, regionId, iconUrl },
      true,
      getPermissionPath(
          AccessPermissionModuleNames.SEGMENT,
          AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
              .CreateSegmentCategory
      )
  );
};

const getSegments = (regionId, skip = 0, limit = 1000) => {
  return fetchGet(
    `${BASE_URL}segments?${jsonToQueryParam({ regionId, skip, limit })}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.SEGMENT,
      AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
          .ListSegments
    )
  );
};

const createSegments = ({ name, regionId,categoryId, filter }) => {
  return fetchPost(
    `${BASE_URL}segments`, { name, regionId,categoryId, filter },
    true,
    getPermissionPath(
      AccessPermissionModuleNames.SEGMENT,
      AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
          .CreateSegment
    )
  );
};

const getActivities = (activityFilters) => {
  //{contactId, fromDate, toDate, eventId}
  return fetchGet(
    `${BASE_URL}activities?${jsonToQueryParam(activityFilters)}`,
      true,
      //TODO: Removed temporarily to fix a permission issue.
    //   getPermissionPath(
    //     AccessPermissionModuleNames.ACTIVITY,
    //     AccessPermissionModules[AccessPermissionModuleNames.ACTIVITY].actions
    //       .ListActivities
    // )
  );
};

// const getContacts = ({ limit = 100, lastEvaluatedKey, regionId }) => {
//     return fetchGetWithTotal(`${BASE_URL}users?${jsonToQueryParam({ limit, skip: lastEvaluatedKey || 0, regionId })}`);
// }

// const filterContacts = (
//   {
//     selectedFilters = [],
//     limit = 100,
//     lastEvaluatedKey,
//     searchKey = "",
//     regionId,
//   },
//   filters
// ) => {
//   return fetchPostWithTotal(
//     `${BASE_URL}users/filter?${jsonToQueryParam({
//       limit,
//       skip: lastEvaluatedKey || 0,
//       regionId,
//       searchKey,
//     })}`,
//     selectedFilters
//   );
// };

// const searchContact = ({ regionId, loyaltyId, limit = 1000, skip = 0 }) => {
//   return fetchGet(
//     `${BASE_URL}users?${jsonToQueryParam({
//       limit,
//       skip,
//       regionId,
//       searchKey: loyaltyId,
//     })}`
//   );
// };

// const getContactById = (contactId) => {
//     return fetchGet(`${BASE_URL}users/${contactId}`);
// }

const getEvents = (queryObj) => {
  return fetchGet(
    `${BASE_URL}events?${jsonToQueryParam(queryObj)}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.EVENT,
      AccessPermissionModules[AccessPermissionModuleNames.EVENT].actions
          .ListEvents
    )
  );
};

const deleteSegment = (id) => {
  return fetchDelete(
    `${BASE_URL}segments/${id}`,
    null,
    null,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.SEGMENT,
      AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
          .DeleteSegment
    )
  );
};

export {
 /* uploadImage,*/
  getContactAttributes,
  getSegmentCategories,
  getSegments,
  getActivities,
  //   filterContacts,
  createSegments,
  getEvents,
  //searchContact,
  deleteSegment,
  createSegmentCategory
};
