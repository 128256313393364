import React, { useRef, useCallback, useEffect } from "react";
import { Button, Popover, Overlay, DateRangeSelector, IcIcon } from '@shoutout-labs/shoutout-themes-enterprise';
import { createStaticRanges } from 'react-date-range';
import moment from 'moment';
import { faCalendar } from 'FaICIconMap';
import useToggle from '../../Hooks/toggleState';
import './DateRangeSelector.css';

const defaultRanges = createStaticRanges([
    {
        label: 'Today',
        range: () => ({
            startDate: moment().toDate(),
            endDate: moment().toDate()
        })
    },
    {
        label: 'Last 7 Days',
        range: () => ({
            startDate: moment().subtract(7, 'days').toDate(),
            endDate: moment().subtract(1, 'days').toDate()
        })
    },
    {
        label: 'Last 14 Days',
        range: () => ({
            startDate: moment().subtract(14, 'days').toDate(),
            endDate: moment().subtract(1, 'days').toDate()
        })
    },
    {
        label: 'Last 28 Days',
        range: () => ({
            startDate: moment().subtract(28, 'days').toDate(),
            endDate: moment().subtract(1, 'days').toDate()
        })
    },
    {
        label: 'Last 90 Days',
        range: () => ({
            startDate: moment().subtract(90, 'days').toDate(),
            endDate: moment().subtract(1, 'days').toDate()
        })
    },
]);

const formatDateSelection = ({ startDate, endDate }) => {
    return (
        moment(startDate).format("MMM DD, YYYY") + ' - ' + moment(endDate).format("MMM DD, YYYY")
    )
}
const initialRangeState = { startDate: moment().subtract(7, 'days').startOf('day').toDate(), endDate: moment().toDate(), key: 'selection' }


const DateRangeWidget = ({ onSubmit, customRanges, selectedDateRange = initialRangeState, show = false, ...rest }) => {
    const [showSelection, toggleShowSelection] = useToggle(false);

    const chooseBtn = useRef(null);


    const onClickDone = useCallback((data) => {

        onSubmit(data);
        toggleShowSelection();

    }, [onSubmit, toggleShowSelection])

    useEffect(() => {
        if (show !== showSelection) {
            toggleShowSelection();
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show,showSelection])


    const dateRanges = customRanges || defaultRanges;
    return (
        <div className="date-range-selector">

            <Button variant="default" size="sm" className="search-pre-sec" onClick={toggleShowSelection} ref={chooseBtn} ><IcIcon icon={faCalendar} />&nbsp;{formatDateSelection(selectedDateRange)}
            </Button>

            <Overlay
                show={showSelection}
                target={chooseBtn.current}
                placement="bottom"
                containerPadding={20}
            >
                <Popover id="data-range-selection">



                    <DateRangeSelector
                        onSubmit={onClickDone}
                        selectedDateRange={selectedDateRange}
                        showSelectionPreview={true}
                        staticRanges={dateRanges}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        {...rest}
                    />

                </Popover>
            </Overlay>

        </div>
    );
}

export default DateRangeWidget;
