import React from 'react';
import {
    Modal,
    ShIcon,
  } from "@shoutout-labs/shoutout-themes-enterprise";
import { ShInformationCircleOutline } from "@shoutout-labs/shoutout-themes-enterprise/lib/ShoutOUTIcon";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    // Log error info somewhere
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
            <Modal.Dialog aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body className="p-4 text-center">
                        <div className="my-3 text-danger">
                            <ShIcon icon={ShInformationCircleOutline} size="4x" />
                        </div>
                        <h2>Something went wrong.</h2>
                        <div>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </div>
                 </Modal.Body>
            </Modal.Dialog>
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary