import {
    faCreditCard, 
    faUser,
    faUserPlus, 
    faGift,
    faHeart,
    faUsdCircle,
    faCoins
  } from "FaICIconMap";
  
const NotificationTemplatesTypes = {
    DONATION_NOTIFICATION: {
        name : "Redeem Points for Donation",
        value : "DONATION_NOTIFICATION",
        icon : faHeart
    },
    EARN_POINTS_BILL_NOTIFICATION: {
        name : "Earn Points for Bill Value",
        value : "EARN_POINTS_BILL_NOTIFICATION",
        icon : faCreditCard
    },
    EARN_POINTS_NOTIFICATION: {
        name : "Earn Points for Point Value",
        value : "EARN_POINTS_NOTIFICATION",
        icon : faCoins
    },
    REDEEEM_POINTS_NOTIFICATION: {
        name : "Redeem Points",
        value : "REDEEEM_POINTS_NOTIFICATION",
        icon : faUsdCircle
    },
    ENROLL_NOTIFICATION : {
        name : "Enroll on the Loyalty Program",
        value : "ENROLL_NOTIFICATION",
        icon : faUser
        
    },
    SIGNUP_NOTIFICATION : {
        name : "Sign Up Customer Portal",
        value : "SIGNUP_NOTIFICATION",
        icon : faUserPlus
    },
    REDEEM_REWARD_NOTIFICATION : {
        name : "Redeem Rewards",
        value : "REDEEM_REWARD_NOTIFICATION",
        icon : faGift
    }
};
    
    export default NotificationTemplatesTypes;