import { fetchPut, fetchGet, preProcessBodyData } from "./CommonServiceUtils";

import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";
const BASE_URL = `${Constants.BASE_URL}loyaltyservice/organizations`;

const getOrganizationDetails = () => {
    return fetchGet(
        BASE_URL,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.ORGANIZATION,
            AccessPermissionModules[AccessPermissionModuleNames.ORGANIZATION]
                .actions.GetOrganization
        )
    );
};
const editOrganizationDetails = ({
    organizationName,
    organizationLogoImageUrl,
    organizationFavicon,
    organizationAppTitle,
    address,
    configuration,
}) => {
    return fetchPut(
        BASE_URL,
        preProcessBodyData({
            organizationName,
            organizationLogoImageUrl,
            organizationFavicon,
            organizationAppTitle,
            address,
            configuration,
        }),
        true,
        getPermissionPath(
            AccessPermissionModuleNames.ORGANIZATION,
            AccessPermissionModules[AccessPermissionModuleNames.ORGANIZATION]
                .actions.UpdateOrganization
        )
    );
};

export { getOrganizationDetails, editOrganizationDetails };
