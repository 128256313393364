import {
    fetchGet,
    fetchPut,
    fetchPost,
    fetchDelete,
    jsonToQueryParam,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { getPermissionPath } from "Utils";
import { AccessPermissionModuleNames, AccessPermissionModules } from "Data";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;
const JOB_EXECUTIONS = `${BASE_URL}jobexecutions`;
const JOBS_URL = `${BASE_URL}jobs`;
const JOB_TYPES_URL = `${BASE_URL}jobtypes`;

// * Job Executions Endpoints.
// TODO: Add permissions to job executions related endpoints.

// * Query params: limit, skip, regionId, jobId, status.
const getJobExecutions = (queryObj) =>
    fetchGet(
        `${JOB_EXECUTIONS}?${jsonToQueryParam(queryObj)}`, 
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOB_EXECUTIONS,
            AccessPermissionModules[AccessPermissionModuleNames.JOB_EXECUTIONS].actions
                .ListJobExecutions 
        )
    );


const getJobExecutionById = (jobExecutionId) =>
    fetchGet(
        `${JOB_EXECUTIONS}/${jobExecutionId}`, 
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOB_EXECUTIONS,
            AccessPermissionModules[AccessPermissionModuleNames.JOB_EXECUTIONS].actions
                .GetJobExecution
        )
    );

const getJobExecutionReport = (jobExecutionId) => 
    fetchGet(
        `${JOB_EXECUTIONS}/downloadfile/${jobExecutionId}`, 
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOB_EXECUTIONS,
            AccessPermissionModules[AccessPermissionModuleNames.JOB_EXECUTIONS].actions
                .GetJobExecution 
        )
    );

// * ---

// * Jobs Endpoints.

// * Query params: limit, skip, regionId, jobTypeId, searchKey, status.
const getJobs = (queryObj) =>
    fetchGet(
        `${JOBS_URL}?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOBS,
            AccessPermissionModules[AccessPermissionModuleNames.JOBS].actions
                .ListJobs
        )
    );

const createJob = (payload) =>
    fetchPost(
        `${JOBS_URL}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOBS,
            AccessPermissionModules[AccessPermissionModuleNames.JOBS].actions
                .CreateJob
        )
    );

const getJobById = (jobId) =>
    fetchGet(
        `${JOBS_URL}/${jobId}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOBS,
            AccessPermissionModules[AccessPermissionModuleNames.JOBS].actions
                .GetJob
        )
    );

const updateJobById = (jobId, payload) =>
    fetchPut(
        `${JOBS_URL}/${jobId}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOBS,
            AccessPermissionModules[AccessPermissionModuleNames.JOBS].actions
                .UpdateJob
        )
    );

const archiveJob = (jobId) =>
    fetchDelete(
        `${JOBS_URL}/${jobId}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOBS,
            AccessPermissionModules[AccessPermissionModuleNames.JOBS].actions
                .DeleteJob
        )
    );

// * ---

// * Job Types Endpoints.

// * Query params: limit, skip, searchKey, status.
const getJobTypes = ({ limit = 100, skip = 0, ...paramsRest }) =>
    fetchGet(
        `${JOB_TYPES_URL}?${jsonToQueryParam({ limit, skip, ...paramsRest })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOB_TYPES,
            AccessPermissionModules[AccessPermissionModuleNames.JOB_TYPES]
                .actions.ListJobTypes
        )
    );

const getJobByTypeId = (jobTypeId) =>
    fetchGet(
        `${JOB_TYPES_URL}/${jobTypeId}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOB_TYPES,
            AccessPermissionModules[AccessPermissionModuleNames.JOB_TYPES]
                .actions.GetJobType
        )
    );

const updateJobTypeById = (jobTypeId, paylaod) =>
    fetchPut(
        `${JOB_TYPES_URL}/${jobTypeId}`,
        paylaod,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.JOB_TYPES,
            AccessPermissionModules[AccessPermissionModuleNames.JOB_TYPES]
                .actions.UpdateJobType
        )
    );

// * ---

export {
    getJobExecutions,
    getJobExecutionById,
    getJobs,
    createJob,
    getJobById,
    updateJobById,
    archiveJob,
    getJobTypes,
    getJobByTypeId,
    updateJobTypeById,
    getJobExecutionReport,
};
