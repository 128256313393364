const FraudRulesStatusMap = {
    ENABLED: {
        status:"ENABLED",
        text: "Enable",
        color: "success",
        textColor:"text-success",
        buttonColor:"outline-success"
    },
    DISABLED: {
        status:"DISABLED",
        text: "Disable",
        color: "warning",
        textColor:"text-warning",
        buttonColor:"outline-warning"
    }
}

export default FraudRulesStatusMap;