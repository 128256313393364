const MemberStatus = {
    ACTIVE: "ACTIVE",
    SUSPENDED: "SUSPENDED",
    ARCHIVED: "ARCHIVED",
};

const MemberTypes = {
    PRIMARY: "PRIMARY",
    SECONDARY: "SECONDARY",
    CHARITY: "CHARITY",
};
const PreferredContactMethods = {
    Email: "EMAIL",
    Mobile: "MOBILE",
    EmailAndMobile: "EMAIL_AND_MOBILE",
};

const IdTypeSelector = [
    { value: "NATIONAL_ID", id: "nationalID", label: "National Id" },
    { value: "DRIVER_LICENSE", id: "driversLicense", label: "Drivers License" },
    { value: "PASSPORT", id: "passport", label: "Passport" },
];

const IdentificationNumbersLabelColors = {
    PASSPORT: "info",
    DRIVER_LICENSE: "primary",
    NATIONAL_ID: "secondary",
};

const AddPointsTabs = {
    BILL: "BILL",
    POINTS: "POINTS",
};

export {
    MemberStatus,
    MemberTypes,
    PreferredContactMethods,
    IdTypeSelector,
    IdentificationNumbersLabelColors,
    AddPointsTabs,
};
