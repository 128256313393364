import Constants from "Constants";
import { generateUserAttribute, getPermissionPath } from "Utils";
import {
    fetchDelete,
    fetchGet,
    fetchGetWithTotal,
    fetchPost,
    fetchPut,
    jsonToQueryParam,
    preProcessBodyData,
} from "./CommonServiceUtils";
import { AccessPermissionModuleNames, AccessPermissionModules } from "../Data";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const getCharities = ({
    skip = 0,
    regionId,
    limit,
    affinityGroupId,
    loyaltyId,
    parentMemberId,
    sortBy,
    searchKey,
    tierId,
    type,
    sortDirection,
    projection,
}) => {
    return fetchGetWithTotal(
        `${BASE_URL}charities?${jsonToQueryParam({
            limit,
            skip: skip,
            regionId,
            ...(sortBy ? { sortBy } : {}),
            ...(sortDirection ? { sortDirection } : {}),
            ...(affinityGroupId ? { affinityGroupId } : {}),
            ...(searchKey ? { searchKey } : {}),
            ...(type ? { type } : {}),
            ...(tierId ? { tierId } : {}),
            ...(loyaltyId ? { loyaltyId } : {}),
            ...(parentMemberId ? { parentMemberId } : {}),
            ...(projection
                ? { fields: generateUserAttribute(projection) }
                : {}),
        })}`,
        true,
    getPermissionPath(
            AccessPermissionModuleNames.CHARITIES,
            AccessPermissionModules[AccessPermissionModuleNames.CHARITIES].actions
               .ListCharities
        )
    );
};

const getCharityById = (charityId) => {
    return fetchGet(
        `${BASE_URL}charities/${charityId}`,
        true,
         getPermissionPath(
           AccessPermissionModuleNames.CHARITIES,
           AccessPermissionModules[AccessPermissionModuleNames.CHARITIES].actions
              .GetCharity
       )
    );
};

const createNewCharity = (payload) => {
    return fetchPost(
        `${BASE_URL}charities`,
        preProcessBodyData(payload),
        true,
         getPermissionPath(
           AccessPermissionModuleNames.CHARITIES,
           AccessPermissionModules[AccessPermissionModuleNames.CHARITIES].actions
          .CreateCharity
         )
    );
};

const updateCharity = (id, payload) => {
    return fetchPut(
        `${BASE_URL}charities/${id}`,
        payload,
        true,
         getPermissionPath(
             AccessPermissionModuleNames.CHARITIES,
             AccessPermissionModules[AccessPermissionModuleNames.CHARITIES].actions
               .UpdateCharity
       )
    );
};

const forgetCharity = (payload) => {
    return fetchPost(`${BASE_URL}charities/erase`, payload,true,getPermissionPath(
        AccessPermissionModuleNames.CHARITIES,
        AccessPermissionModules[AccessPermissionModuleNames.CHARITIES].actions
            .EraseCharity
    ));
};

const exportCharityRequest = (payload) => {
    return fetchPost(`${BASE_URL}charities/export`, payload,true,getPermissionPath(
        AccessPermissionModuleNames.CHARITIES,
        AccessPermissionModules[AccessPermissionModuleNames.CHARITIES].actions
            .ExportCharity
    ));
};

const archiveCharity = (id) => {
    return fetchDelete(
        `${BASE_URL}charities/${id}`,
        null,
        null,
        true,
         getPermissionPath(
           AccessPermissionModuleNames.CHARITIES,
           AccessPermissionModules[AccessPermissionModuleNames.CHARITIES]
           .actions.DeleteCharity
         )
    );
};

const changeCharityAccountStatus = (payload) => {
    return fetchPost(
        `${BASE_URL}charities/status`,
        payload,
        true,
         getPermissionPath(
            AccessPermissionModuleNames.CHARITIES,
            AccessPermissionModules[AccessPermissionModuleNames.CHARITIES]
             .actions.UpdateCharity
         )
    );
};

const loadAllCharities = (args) => {
    return new Promise(async (resolve, reject) => {
        const limit = 500;
        let totalCount = 0,
            charities = [];
        try {
            do {
                const charitiesResponse = await getCharities({
                    limit,
                    skip: charities.length,
                    ...args,
                });
                totalCount = charitiesResponse.data.total;
                charities = [...charities, ...charitiesResponse.data.items];
            } while (charities.length < totalCount);
            resolve(charities);
        } catch (e) {
            reject(e);
        }
    });
};

export {
    getCharities,
    getCharityById,
    createNewCharity,
    updateCharity,
    archiveCharity,
    changeCharityAccountStatus,
    loadAllCharities,
    forgetCharity,
    exportCharityRequest
};
