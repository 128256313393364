import React, {useCallback, useContext, useEffect, useReducer} from "react";
import Medal from "../assets/images/medal.svg";
import Group from "../assets/images/group.svg";
import {
    exportFilterMembers,
    filterMembers,
    getContacts,
    getSegmentCategories,
    getSegments,
} from "../Services";
import { buildMemberFilterConfig, getMongoDBQuery } from "../Utils";
import {DataContext} from "./dataContext";
import {UserContext} from "./userContext";
import {AccessPermissionModuleNames, AccessPermissionModules} from "Data";
import { toast } from "react-toastify";

const MembersContext = React.createContext();

const resetMemberStatus = {
    memberList: [],
    limit: 25,
    skip: 1,
    isLoading: true,
    totalCount: 0,
};

const initialState = {
    segments: {},
    selectedSegmentId: null,
    sortDirection: "",
    sortBy: "",
    isLoadingSegments: true,
    segmentCategories: {},
    searchText: "",
    currentFilters: {},
    filterConfig: {},
    otherCategoryId:'',
    selectedLoyaltyCardNumber: "",
    isExporting: false,
    ...resetMemberStatus,
};

const MembersContextActions = {
    SET_MEMBERS: "setMembers",
    SET_IS_LOADING: "setIsLoading",
    SET_IS_EXPORTING: "setIsExporting",
    UPDATE_MEMBER: "updateMember",
    SET_SEGMENTS_CATEGORIES: "setSegmentCategories",
    SET_SEGMENTS: "setSegments",
    SET_IS_LOADING_SEGMENTS: "setIsLoadingSegments",
    SEARCHING: "memberSearching",
    CHANGE_SEARCH_TEXT: "changeSearchText",
    SET_PAGINATION_INFO: "setPaginationInfo",
    MEMBER_DATA_RESET: "memberDataReset",
    SET_SORTING_ORDER: "setSortingOrder",
    SET_CURRENT_FILTERS: "setCurrentFilters",
    SET_FILTER_CONFIG: "setFilterConfig",
    SET_SELECTED_SEGMENT: "setSelectedSegment",
    UPDATE_SEGMENTS: "updateSegments",
    SET_CARD_NUMBER: "setCardNumber",
};

const reducer = (state, action) => {
    console.debug("Action:", action);
    switch (action.type) {
        case MembersContextActions.SET_MEMBERS: {
            const newState = {
                ...state,
                isLoading: false,
                memberList: action.members.items,
                totalCount: action.members.total,
            };
            if (action.shouldReset) {
                newState.skip = 1;
            }
            // if (action.shouldReset) {
            //   // newState.memberList = action.members.items;
            //  // newState.totalCount = action.members.total;
            //   newState.skip = 0;
            // } else {
            //   // newState.memberList = [...state.memberList, ...action.members.items];
            //   //newState.totalCount = action.members.total;
            // }
            return newState;
        }
        case MembersContextActions.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.status,
            };
        }

        case MembersContextActions.SET_IS_LOADING_SEGMENTS: {
            return {
                ...state,
                isLoadingSegments: action.status,
            };
        }
        case MembersContextActions.SET_SEGMENTS_CATEGORIES: {
            return {
                ...state,
                isLoadingSegments: false,
                // segments: action.segments,
                segmentCategories: action.categories || {},
                otherCategoryId:action.otherCategoryId || "",
            };
        }

        case MembersContextActions.SET_SEGMENTS: {
            return {
                ...state,
                segments: action.segments,
            };
        }

        case MembersContextActions.UPDATE_MEMBER: {
            return {
                ...state,

                memberList: state.memberList.map((item) => {
                    if (item._id === action.member._id) {
                        return action.member;
                    }
                    return item;
                }),
            };
        }

        case MembersContextActions.CHANGE_SEARCH_TEXT: {
            return {
                ...state,
                searchText: action.searchText,
                ...(state.searchText!==action.searchText?{...resetMemberStatus}:{}),
            };
        }
        case MembersContextActions.SET_PAGINATION_INFO: {
            const newState = {
                ...state,
                isLoading: true,
            };
            if (action.limit) {
                newState.limit = action.limit;
            } else {
                newState.skip = action.skip;
            }
            return newState;
        }
        case MembersContextActions.MEMBER_DATA_RESET: {
            return {
                ...state,
                memberList: [],
                limit: action.limit,
                skip: action.skip,
                isLoading: true,
            };
        }
        case MembersContextActions.SET_SORTING_ORDER: {
            return {
                ...state,
                sortDirection: action.sortDirection,
                sortBy: action.sortBy,
                memberList: [],
                skip: 1,
                isLoading: true,
            };
        }

        case MembersContextActions.SET_CURRENT_FILTERS: {
            console.debug(JSON.stringify(action.filters));
            return {
                ...state,
                currentFilters: action.filters || {},
                searchText: "",
                ...resetMemberStatus,
            };
        }

        case MembersContextActions.SET_FILTER_CONFIG: {
            return {
                ...state,
                filterConfig: action.filterConfig,
            };
        }

        case MembersContextActions.SET_SELECTED_SEGMENT: {
            return {
                ...state,
                selectedSegmentId: action.selectedSegmentId,
                currentFilters: action.filters,
                searchText: "",
                ...resetMemberStatus,
            };
        }

        case MembersContextActions.UPDATE_SEGMENTS: {
            return {
                ...state,
                segments: {
                    ...state.segments,
                    [action.segment._id]: action.segment,
                },
            };
        }
        case MembersContextActions.SET_CARD_NUMBER: {
            return {
                ...state,
                selectedLoyaltyCardNumber: action.selectedLoyaltyCardNumber,
            };
        }
        case MembersContextActions.SET_IS_EXPORTING: {
            return {
                ...state,
                isExporting: action.status,
            };
        }
        default:
            return state;
    }
};

let searchTimeout;

const MembersContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isAuth, regionId, config, userConfigLoaded,selectedRegion,isAuthorizedForAction } = useContext(UserContext);
    const { contactAttributes,tags,tiers,affinityGroups} = useContext(DataContext);
    const loadMembers = useCallback(
        async ({ shouldReset = false, skip }) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.MEMBER,
                    AccessPermissionModules[AccessPermissionModuleNames.MEMBER]
                        .actions.ListMembers
                )
            ) {
                try {
                    dispatch({
                        type: MembersContextActions.SET_IS_LOADING,
                        status: true,
                    });

                    let contactResponse;
                    const newSkip = ((skip || state.skip) - 1) * state.limit;
                    //check filters are not empty
                    if (
                        state.currentFilters &&
                        Object.keys(state.currentFilters)?.length > 0
                    ) {
                        const filters = getMongoDBQuery(
                            state.currentFilters,
                            state.filterConfig
                        );
                   /*     if(filters.hasOwnProperty('birthDate')){
                            filters["birthDate"] = moment(moment(filters["birthDate"]).utc().format('YYYY-MM-DD'), "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
                        }*/

                        contactResponse = await filterMembers(
                            {
                                skip: newSkip,
                                limit: state.limit,
                                ...(state.sortDirection?{ sortDirection:
                                        state.sortDirection?.toUpperCase()}:{}),
                                ...(state.sortBy?{sortBy:state.sortBy}:{}),
                                searchKey: state.searchText,
                                regionId: regionId,
                                projection: config.memberTableColumns,
                            },
                            filters
                        );
                    } else {
                        contactResponse = await getContacts({
                            skip: newSkip,
                            regionId: regionId,
                            limit: state.limit,
                            sortDirection: state.sortDirection?.toUpperCase(),
                            sortBy: state.sortBy,
                            searchKey: state.searchText,
                            projection: config.memberTableColumns,
                        });
                    }

                    dispatch({
                        type: MembersContextActions.SET_MEMBERS,
                        members: contactResponse.data,
                        shouldReset,
                    });
                } catch (e) {
                    console.error(e);
                    dispatch({
                        type: MembersContextActions.SET_IS_LOADING,
                        status: false,
                    });
                }
            } else {
                dispatch({
                    type: MembersContextActions.SET_IS_LOADING,
                    status: false,
                });
            }
        },
        [
            dispatch,
            state.limit,
            state.skip,
            state.sortDirection,
            state.sortBy,
            state.searchText,
            state.currentFilters,
            regionId,
            state.filterConfig,
            config.memberTableColumns,
            isAuthorizedForAction
        ]
    );

    const resetMember = useCallback(
        async ({ skip, pageReset, limit }) => {
            if (pageReset) {
                await dispatch({
                    type: MembersContextActions.MEMBER_DATA_RESET,
                    skip: skip,
                    limit: limit,
                });
            }
        },
        [dispatch]
    );

    const onChangePagination = useCallback(
        async ({ skip = state.skip, limit = state.limit }) => {
            if (state.limit !== limit) {
                dispatch({
                    type: MembersContextActions.SET_PAGINATION_INFO,
                    limit: limit,
                });
            } else {
                //if skip change, need to load the data manually without resetting
                await dispatch({
                    type: MembersContextActions.SET_PAGINATION_INFO,
                    skip: skip,
                });
                loadMembers({ skip: skip });
            }
        },
        [dispatch, state.skip, state.limit, loadMembers]
    );

    const setSortingOrder = useCallback(
        async ({ sortBy, sortDirection }) => {
            try {
                if (
                    !(sortDirection === state.sortDirection) ||
                    !(sortBy === state.sortBy)
                ) {
                    dispatch({
                        type: MembersContextActions.SET_SORTING_ORDER,
                        sortDirection: sortDirection,
                        sortBy: sortBy,
                    });
                }
            } catch (e) {
                console.error(e);
                dispatch({
                    type: MembersContextActions.SET_IS_LOADING,
                    status: false,
                });
            }
        },
        [dispatch, state.sortDirection, state.sortBy]
    );

    const loadSegmentsCategories = useCallback(
        async (silent = false) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.SEGMENT,
                    AccessPermissionModules[AccessPermissionModuleNames.SEGMENT]
                        .actions.ListSegmentCaregories
                )
            ) {
                try {
                    if (!silent) {
                        dispatch({
                            type: MembersContextActions.SET_IS_LOADING_SEGMENTS,
                            status: true,
                        });
                    }
                    const segmentCategoriesResponse = await getSegmentCategories(
                        regionId
                    );

                    const transformedSegmentCategories =
                        segmentCategoriesResponse.items.reduce(
                            (result, { _id, name, createdOn, iconUrl }) => {
                                result[_id] = {
                                    _id,
                                    name,
                                    createdOn,
                                    segmentIds: new Set(),
                                    iconUrl,
                                };
                                // segments.forEach((segment) => {
                                //   if (segment) {
                                //     const {
                                //       _id: segmentId,
                                //       name,
                                //       createdOn,
                                //       filters,
                                //       categoryId,
                                //     } = segment;
                                //     result.segments[segmentId] = {
                                //       name,
                                //       createdOn,
                                //       filters,
                                //       categoryId,
                                //     };
                                //     result.categories[_id].segmentIds.add(segmentId);
                                //   }
                                //   return result;
                                // }, {});

                                return result;
                            },

                            {}
                        );
                    let otherCategoryId="";
                    Object.keys(transformedSegmentCategories).forEach((key)=>{
                        if(transformedSegmentCategories[key].name==="Other"){
                            otherCategoryId=transformedSegmentCategories[key]._id
                        }})
                    dispatch({
                        type: MembersContextActions.SET_SEGMENTS_CATEGORIES,
                        otherCategoryId:otherCategoryId,
                        categories: {...transformedSegmentCategories,
                            tier:{
                                createdOn: new Date(),
                                iconUrl: Medal,
                                name: "Tier",
                                segmentIds: new Set(),
                                /*   tierIds:tiers.map(tier=>tier._id),*/
                                _id: "tier.tierId",
                            },
                            affinityGroup:{
                                createdOn: new Date(),
                                iconUrl: Group,
                                name: "Affinity Group",
                                segmentIds: new Set(),
                                /*      affinityGroupIds:affinityGroups.map(affinityGroup=>affinityGroup._id),*/
                                _id: "affinityGroup.affinityGroupId",
                            }
                        }
                    });
                } catch (e) {
                    console.error(e);
                    if (!silent) {
                        dispatch({
                            type: MembersContextActions.SET_IS_LOADING_SEGMENTS,
                            status: false,
                        });
                    }
                }
            }
        },
        [regionId,isAuthorizedForAction, dispatch]
    );

    const loadSegments = useCallback(async () => {
        if (
            isAuthorizedForAction(
                AccessPermissionModuleNames.SEGMENT,
                AccessPermissionModules[AccessPermissionModuleNames.SEGMENT]
                    .actions.ListSegments
            )
        ) {
            try {
                const segmentsResponse = await getSegments(regionId);
                if(state.otherCategoryId){
                    const withoutOtherCategory= segmentsResponse.items.filter(item=>item.categoryId!==state.otherCategoryId)
                    const otherCategory= segmentsResponse.items.filter(item=>item.categoryId===state.otherCategoryId).map(item=>{
                        const filter = getMongoDBQuery(
                            item.filter,
                            state.filterConfig
                        );
                        if(filter&&filter.hasOwnProperty('affinityGroup.affinityGroupId')){
                            item.categoryId="affinityGroup"
                        }
                        if(filter&&filter.hasOwnProperty('tier.tierId')){
                            item.categoryId="tier"
                        }
                        return item
                    })
                    dispatch({
                        type: MembersContextActions.SET_SEGMENTS,
                        segments: [...otherCategory,...withoutOtherCategory],
                    });
                }
            } catch (e) {
                dispatch({
                    type: MembersContextActions.SET_IS_LOADING_SEGMENTS,
                    status: false,
                });
            }
        }
    }, [isAuthorizedForAction,dispatch, regionId, state.otherCategoryId, state.filterConfig]);

    const updateSegments = useCallback(
        (/*segment*/) => {
          /*  dispatch({
                type: MembersContextActions.UPDATE_SEGMENTS,
                segment,
            });
*/          loadSegmentsCategories(true);
            //reload categories if the category of the new segment is not available
          /*  if (!state.segmentCategories[segment.categoryId]) {
                loadSegmentsCategories(true);
            }*/
        },
        [loadSegmentsCategories]
    );

    const updateMember = useCallback(
        (member) => {
            dispatch({ type: MembersContextActions.UPDATE_MEMBER, member });
        },
        [dispatch]
    );

    const onChangeSearchText = useCallback(
        (searchText) => {
            dispatch({
                type: MembersContextActions.CHANGE_SEARCH_TEXT,
                searchText,
            });
        },
        [dispatch]
    );

    const setCurrentFilters = useCallback(
        (filters) => {
            dispatch({
                type: MembersContextActions.SET_CURRENT_FILTERS,
                filters,
            });
        },
        [dispatch]
    );

    const setFilterConfig = useCallback(
        (filterConfig) => {
            dispatch({
                type: MembersContextActions.SET_FILTER_CONFIG,
                filterConfig,
            });
        },
        [dispatch]
    );

    const selectSegment = useCallback(
        (segment, segmentId) => {
            dispatch({
                type: MembersContextActions.SET_SELECTED_SEGMENT,
                selectedSegmentId: segmentId,
                filters: segment.filter,
            });
        },
        [dispatch]
    );

    const setSelectedLoyaltyCardNo = useCallback(
        (selectedLoyaltyCardNumber) => {
            dispatch({
                type: MembersContextActions.SET_CARD_NUMBER,
                selectedLoyaltyCardNumber,
            });
        },
        [dispatch]
    );

    const onExportMembers = useCallback(async (notificationEmails) => {
        try {
            dispatch({
                type: MembersContextActions.SET_IS_EXPORTING,
                status: true,
            });
            await exportFilterMembers(
                {
                    sortDirection:
                    state.sortDirection?.toUpperCase(),
                    sortBy: state.sortBy,
                    regionId: selectedRegion._id,
                    projection: config.memberTableColumns,
                    notificationEmails:notificationEmails
                },
                { ...( (state.currentFilters && Object.keys(state.currentFilters)?.length > 0)
                    ? getMongoDBQuery(state.currentFilters, state.filterConfig)
                    : {})},
            );
            toast.success("You will receive the exported file to the provided email");
        } catch (e) {
            console.error(e);
            toast.error(
                <div>
                    Failed to export member list!
                    <br/>
                    Error: {e.message || "Please try again."}
                </div>
            );
        } finally {
            dispatch({
                type: MembersContextActions.SET_IS_EXPORTING,
                status: false,
            });
        }
    }, [selectedRegion, dispatch,config.memberTableColumns,state]);

    useEffect(() => {
        if (isAuth && userConfigLoaded) {
            loadMembers({ shouldReset: true, skip: 1 });
        }
        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        //add only the dependencies which need to reset the current data
        isAuth,
        // state.skip,
        state.limit,
        state.sortBy,
        state.sortDirection,
        state.currentFilters,
        state.searchText,
        userConfigLoaded,
        config.memberTableColumns,
    ]);

    useEffect(() => {
        if (isAuth) {
            loadSegmentsCategories();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth,tiers,affinityGroups]);

    useEffect(() => {
        if (isAuth) {
            loadSegments();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth,state.segmentCategories]);

    //auto update filterConfig
    useEffect(() => {
        if (Object.keys(contactAttributes).length > 0) {
            const config = buildMemberFilterConfig(
                {...contactAttributes,"tier.tierId":{
                    label: "Tier",
                    type: "select",
                     fieldSettings:tiers.map((tier)=>({
                            title: tier.name,
                            value: tier._id
                     }))
                    },"affinityGroup.affinityGroupId":{
                        label: "Affinity Group",
                        type: "select",
                      fieldSettings:affinityGroups.map((affinityGroup)=>({
                            title: affinityGroup.name,
                            value: affinityGroup._id
                        }))
                    }},
                tags || []
            );

            setFilterConfig(config);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactAttributes, tags,affinityGroups,tiers]);

    const value = {
        ...state,
        resetMember,
        setSortingOrder,
        onChangePagination,
        loadMembers,
        updateMember,
        onChangeSearchText,
        loadSegmentsCategories,
        setCurrentFilters,
        setFilterConfig,
        selectSegment,
        updateSegments,
        loadSegments,
        setSelectedLoyaltyCardNo,
        onExportMembers,
    };
    console.debug("Member Context:", state, "Values : ", value);
    return (
        <MembersContext.Provider value={value}>
            {props.children}
        </MembersContext.Provider>
    );
};

const MemberContextConsumer = MembersContext.Consumer;

export { MembersContext, MembersContextProvider, MemberContextConsumer };
