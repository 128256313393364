const MessageLogFilterOptions = {
    CHANNEL: "CHANNEL",
    LOYALTY_ACTIVITY: "LOYALTY_ACTIVITY",
    TO_SMS: "TO_SMS",
    TO_EMAIL: "TO_EMAIL",
    STATUS: "STATUS",
};
const MessageLogChannel = {
    SMS: "SMS",
    EMAIL: "EMAIL",
    PUSH: "PUSH",
    WHATSAPP: "WHATSAPP",
};
const MessageLogStatus = {
    SENT: "SENT",
    OPEN: "OPEN",
    DELIVERED: "DELIVERED",
    QUEUED: "QUEUED",
    FAILED: "FAILED",
    INTERNAL_ERROR: "INTERNAL_ERROR",
};

export { MessageLogFilterOptions, MessageLogStatus, MessageLogChannel };
