const CardStatus = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  PENDING:"PENDING",
  ASSIGNED: "ASSIGNED",
  DEACTIVATED: "DEACTIVATED",
  FAILED:"FAILED",
  READY:"READY"
};

export default CardStatus;
