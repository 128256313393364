import React, { useReducer, useCallback } from "react";
import Keycloak from "keycloak-js";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import AppContext from "../AppContext";
import { LoadingComponent } from "Components/utils";

const KeycloakContext = React.createContext();

const KeycloakContextActions = {
    // SET_DATA: "setData",
    // SET_IS_AUTH: "setIsAuth",
    UPDATE_TOKEN: "setToken",
};

const initialState = {
    // keycloak: null,
    token: null,
    // user: { username: null, email: null },
    // loginUrl: null,
    // logoutUrl: null,
    // registerUrl: null,
    // accountUrl: null,
    // isAuth: false,
    authComplete: false,
};

const keycloakInstance = Keycloak(AppContext.keycloakConfig);
// const logoutStatusCodes = [401, 403];
// const TOKEN_REFRESH_RATE = 10;
// let refreshTokenInterval;

// const authenticate = () => new Promise((resolve, reject) => {
//     const keycloakInstance = Keycloak(AppContext.keycloakConfig);
//     keycloakInstance
//         .init({ onLoad: "login-required" })
//         .then(authenticated => {
//             resolve(keycloakInstance);
//         })
//         .catch(e => {
//         });
// });

const reducer = (state, action) => {
    switch (action.type) {
        // case KeycloakContextActions.SET_DATA: {
        //     const { type, instance, ...rest } = action;
        //     return {
        //         ...state,
        //         keycloak: instance,
        //         ...rest

        //     }
        // }

        // case KeycloakContextActions.SET_IS_AUTH: {
        //     return {
        //         ...state,
        //         isAuth: action.status
        //     }
        // }

        case KeycloakContextActions.UPDATE_TOKEN: {
            return {
                ...state,
                token: action.token,
                authComplete: !!action.token,
            };
        }
        default: {
            return state;
        }
    }
};

// const constantMock = window.fetch;

const KeycloakContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const authEventHandler = useCallback(
        (event, error) => {
            switch (event) {
                case "onAuthError":
                case "onAuthLogout": {
                    dispatch({
                        type: KeycloakContextActions.SET_IS_AUTH,
                        status: false,
                    });
                    break;
                }
                default: {
                }
            }
        },
        [dispatch]
    );

 

    const authTokenHandler = useCallback(({ token }) => {
        try {
            if(token) {
  
                dispatch({
                    type: KeycloakContextActions.UPDATE_TOKEN,
                    token
                });
            }
            else {
                // window.fetch = constantMock;
            }
        } catch (e) {
            console.error(e);
        }
    }, [dispatch]);

    const value = {
        ...state,
        isAuth: keycloakInstance.authenticated || false,
        login: keycloakInstance.login,
        keycloakLogout: keycloakInstance.logout,
        loadUserProfile: keycloakInstance.loadUserProfile
    };

    return (
        <ReactKeycloakProvider
            authClient={keycloakInstance}
            LoadingComponent={<LoadingComponent />}
            onEvent={authEventHandler}
            onTokens={authTokenHandler}
        >
            <KeycloakContext.Provider value={value}>
                {props.children}
            </KeycloakContext.Provider>
        </ReactKeycloakProvider>
    );

    // const keycloakLogout = useCallback((now = true) => {
    //     const { keycloak, logoutUrl, logout } = state;

    //     if (!keycloak) {
    //         return
    //     }

    //     if (now) {
    //         if (keycloak && keycloak.authenticated) {
    //             // Dispatch.success(dispatch, LOGOUT);
    //             logout({ redirectUri: logoutUrl })
    //         }
    //     } else {
    //         setTimeout(() => {
    //             // Dispatch.success(dispatch, LOGOUT);
    //             logout({ redirectUri: logoutUrl })
    //         }, 5000);
    //     }
    // }, [state]);

    // const updateToken = useCallback((token) => {
    //     dispatch({ type: KeycloakContextActions.UPDATE_TOKEN, token });
    // }, [dispatch])
    // const scheduleTokenRefresh = useCallback((keycloak) => {

    //     if (refreshTokenInterval) {
    //         clearInterval(refreshTokenInterval);
    //     }
    //     refreshTokenInterval = setInterval(() => {
    //         keycloak
    //             .updateToken(TOKEN_REFRESH_RATE)
    //             .then(refreshed => {
    //                 if (refreshed) {
    //                     updateToken(keycloak.token);
    //                 } // else, token is still valid

    //             })
    //             .catch((e) => {
    //                 // eslint-disable-next-line no-console
    //                 console.error("Failed to retrieve an updated token or session has expired.", e);
    //                 keycloakLogout({ now: true });
    //             });
    //     }, TOKEN_REFRESH_RATE * 1000);
    // }, [keycloakLogout, updateToken]);

    // const initialize = useCallback(async () => {

    //     const keycloak = await authenticate();

    //     const { token, idTokenParsed: { preferred_username, email } } = keycloak || {}
    //     const login = keycloak.login
    //     const loginUrl = keycloak.createLoginUrl()
    //     const logoutUrl = keycloak.createLogoutUrl()
    //     const logout = keycloak.logout
    //     const registerUrl = keycloak.createRegisterUrl()
    //     const accountUrl = keycloak.createAccountUrl()

    //     dispatch({ type: KeycloakContextActions.SET_DATA, instance: keycloak, token, user: { username: preferred_username, email: email }, login, loginUrl, logout, logoutUrl, registerUrl, accountUrl });
    //     scheduleTokenRefresh(keycloak);

    // }, [dispatch, scheduleTokenRefresh]);

    // useEffect(() => {
    //     initialize();

    //     return () => {
    //         if (refreshTokenInterval) {
    //             clearInterval(refreshTokenInterval);
    //         }
    //     }
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // useEffect(() => {
    //     const { keycloak } = state;

    //     if (keycloak && keycloak.authenticated) {
    //         dispatch({ type: KeycloakContextActions.SET_IS_AUTH, status: true });
    //     } else {
    //         dispatch({ type: KeycloakContextActions.SET_IS_AUTH, status: false });
    //     }
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [state.keycloak]);

    // const value = { ...state, keycloakLogout };
};

const KeycloakContextConsumer = KeycloakContext.Consumer;

export { KeycloakContext, KeycloakContextProvider, KeycloakContextConsumer };
